import YearView from './year-view';
import FoodCalendarTitle from './food-calendar-title';
import { useEffect, useState } from 'react';
import { endOfYear, startOfYear } from 'date-fns';
import { getFoodLogsMin } from '../../data/food-data-service';
import { createFoodCalendar } from '../../domain/food-calendar-provider';
import { IFoodCalendarYear } from '../../food-interfaces';
import { useFoodStore } from '../../data/food-store';

const FoodCalendarHome = () => {

    const [calendarData, setCalendarData] = useState<IFoodCalendarYear|null>(null);

    const currentCalendarYear = useFoodStore(store => store.currentCalendarYear);

    useEffect(() => {

        (async () => {
            const date = new Date(currentCalendarYear, 0, 1); // January is 0

            const fromDate = startOfYear(date).getTime();
            const toDate = endOfYear(date).getTime();
            const data = await getFoodLogsMin(fromDate, toDate);

            const result = createFoodCalendar(date.getFullYear(), data || []);
            setCalendarData(result);
        })();

    }, [currentCalendarYear]);

    return (
        <div className="max-w-full mx-auto py-4">
            <FoodCalendarTitle calendarData={ calendarData } />
            <YearView calendarData={ calendarData } />
        </div>
    )
};

export default FoodCalendarHome;