import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IPlantLocation } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { updatePlantLocation } from '../../../data/flowers-data-service';
import { LightIntensity } from '../../../domain/flowers-provider';
import LightIntensitySelect from '../../light-intensity/light-intensity-select';

interface IEditPlantLocation {
    plantLocation: IPlantLocation;
    close: () => void;
}

const EditPlantLocation = ({ plantLocation, close }: IEditPlantLocation) => {

    const nameRef = useRef<HTMLInputElement>(null);

    const setPlantLocationsData = useFlowersStore(store => store.setPlantLocationsData);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [lightIntensity, setLightIntensity] = useState<LightIntensity>(LightIntensity.BrightLight);
    const [lightIntensityTouched, setLightIntensityTouched] = useState(false);

    const [description, setDescription] = useState('');
    const [isIndoor, setIsIndoor] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;
    const isLightIntensityValid = !lightIntensityTouched || lightIntensity > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    useEffect(() => {
        setName(plantLocation.location_name);
        setDescription(plantLocation.location_desc);
        setLightIntensity(plantLocation.light_intensity);
        setIsIndoor(plantLocation.is_indoor);
    }, [plantLocation]);

    const save = async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        if(lightIntensity <= 0) {
            setLightIntensityTouched(true);
            return;
        }

        setLoading(true);

        const response = await updatePlantLocation(
            plantLocation.location_id,
            name,
            description,
            lightIntensity,
            isIndoor,
        );

        setLoading(false);

        if(!response) {
            showToast('Update plant location error.');
            return;
        }

        setPlantLocationsData(response);
        close();
    };

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((evt.target as HTMLTextAreaElement).value);
    };

    const onLightIntensityChange = (value: LightIntensity) => {
        setLightIntensity(value);
    };

    const onIsIndoorChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.checked;
        setIsIndoor(!value);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onGeneralKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Location Name</div>
                <input
                    ref={nameRef}
                    value={name}
                    onInput={onNameChange}
                    onKeyDown={onNameKeyDown}
                    className={`border rounded px-4 py-2 ${isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200'}`}
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Location Description</div>
                <textarea
                    value={description}
                    onInput={onDescChange}
                    onKeyDown={onGeneralKeyDown}
                    className={`border rounded px-4 py-2`}
                    rows={4}
                />
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Light Intensity</div>

                <LightIntensitySelect
                    value={ lightIntensity }
                    onSelect={ onLightIntensityChange }
                />

                {
                    !isLightIntensityValid &&
                    <div className="text-red-700 text-xs mt-1">The light intensity is required.</div>
                }
            </label>

            <label className="flex items-center mb-4">
                <input
                    checked={ isIndoor }
                    onInput={ onIsIndoorChange }
                    onKeyDown={ onGeneralKeyDown }
                    className={`border rounded px-4 py-2`}
                    type="checkbox"
                />
                <div className="font-bold text-slate-400 ml-4">Is Indoor</div>
            </label>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={24} color={'#717985'}/>
                }

                {
                    !isLoading &&
                    <>
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2 mr-4"
                            onClick={close}
                            type="button">Close
                        </button>

                        <button
                            className="bg-teal-500 text-slate-100 rounded px-6 py-2"
                            onClick={save}
                            type="button">Save
                        </button>
                    </>
                }
            </div>

        </div>
    )
};

export default EditPlantLocation;