import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPlantCalendarData } from '../../data/flowers-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { IPlant } from '../../flowers-interfaces';
import Preloader from '../../../../common/ui/preloader';
import { CalendarIcon } from '../../../../common/ui/icons';
import YearView from './year-view';
import { createPlantWaterCalendar } from '../../domain/flowers-calendar-provider';
import PantWaterCalendarTitle from './title/water-calendar-title';
import { useFlowersStore } from '../../data/flowers-store';

const PlantWateringCalendarHome = () => {

    const year = useFlowersStore(store => store.year);
    const setCalendarData = useFlowersStore(store => store.setCalendarData);

    const [plant, setPlant] = useState<IPlant|null>(null);
    const [loading, setLoading] = useState(false);

    const { plant_id } = useParams();

    const _plant_id = Number(plant_id) || 0;

    useEffect(() => {
        if(!plant) {
            document.title = `Plant Calendar | Flowers`;
        }
        else{
            document.title = `Calendar for ${ plant.plant_name } | Flowers`;
        }
    }, [plant]);

    useEffect(() => {
        (async () => {
            if(!_plant_id) return;

            setLoading(true);
            const response = await getPlantCalendarData(_plant_id);
            setLoading(false);

            if(!response?.isValid) {
                showToast('Get plant calendar data error.');
                return;
            }

            setPlant(response.plant);
            setCalendarData(createPlantWaterCalendar(
                _plant_id,
                year,
                response.logs || []
            ));
        })();
    }, [_plant_id, year, setCalendarData]);

    return (
        <div>

            { loading && <Preloader size={ 30 } /> }

            {
                !loading && !!plant &&
                <>
                    <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                        <CalendarIcon size={ 30 } classes="mr-4"/> { plant.plant_name }
                    </h1>

                    <PantWaterCalendarTitle />
                    <YearView plant_id={ plant.plant_id } />
                </>
            }

        </div>
    )
};

export default PlantWateringCalendarHome;