import { ChangeEvent } from 'react';
import { IPlantLocation } from '../../flowers-interfaces';

interface IPlantLocationsSelect {
    location: IPlantLocation|null;
    locations: IPlantLocation[];
    onSelect: (_location: IPlantLocation|null) => void;
}

const PlantLocationsSelect = ({ location, locations, onSelect }: IPlantLocationsSelect) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const location_id = Number(evt.target.value) || 0;
        onSelect(locations.find(item => item.location_id === location_id));
    };

    return (
        <select
            className="w-full border rounded px-4 py-2"
            value={ location?.location_id ?? 0 }
            onChange={ onChange }>

            <option value={ 0 }>Select Plant Location</option>

            {
                locations.map(plantLocation => {
                    return (
                        <option
                            key={ plantLocation.location_id }
                            value={ plantLocation.location_id }>{ plantLocation.location_name }</option>
                    )
                })
            }
        </select>
    )
};

export default PlantLocationsSelect;