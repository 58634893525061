import Todo from '../todo/todo';
import { useEffect, useState } from 'react';
import { formatTagsData } from '../../domain/tags-provider';
import { getTagsData } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import TagTitle from './tag-title';
import { TOGGLE_TODO_EVENT } from '../todo/todo-title';
import { usePlannerStore } from '../../data/planner-store';

const TagsList = () => {

    const tagsData = usePlannerStore(state => state.tagsData);
    const setTagsData = usePlannerStore(state => state.setTagsData);
    const combinedProject = usePlannerStore(state => state.combinedProject);
    const hideFutureTagsTasks = usePlannerStore(state => state.hideFutureTagsTasks);
    const [rerender, setReRender] = useState(1);

    useEffect(() => {

        (async () => {
            const response = await getTagsData();

            if(!response?.isValid) {
                showToast('Get tags data error.');
                return;
            }

            setTagsData(formatTagsData(response));
        })();

    }, [setTagsData, combinedProject, rerender]);

    useEffect(() => {
        const rerender = () => {
            setReRender(value => value + 1);
        };

        document.addEventListener(TOGGLE_TODO_EVENT, rerender);

        return () => {
            document.removeEventListener(TOGGLE_TODO_EVENT, rerender);
        }
    }, []);

    return (
        <div>
            {
                tagsData?.tags.map((tag, index) => {

                    const todos = tag.isClosed ? [] : hideFutureTagsTasks ? tag.todos.filter(todo => !todo.isFutureTodo) : tag.todos;

                    return (
                        <div key={ tag.code }>
                            <div className="flex items-center bg-indigo-50 text-lg font-semibold py-2 px-4 rounded mb-4">
                                <span className="mr-2 font-normal text-base">{ index + 1 }.</span>
                                <TagTitle tag={ tag } />
                            </div>

                            <div className="ml-4 mb-4">
                                {
                                    !tag.isClosed && todos.map((todo, index) => {

                                        return (
                                            <Todo
                                                key={ `todo-${ todo.todo_id}` }
                                                todo={ todo }
                                                isFirst={ index === 0 }
                                                isLast={ index === todos.length - 1 }
                                                nestLevel={ 0 }
                                                showTodoLink={ true }
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default TagsList;