import { useAuthStatus } from '../../auth/hooks/auth-status-hook';
import { MouseEvent } from 'react';
import Preloader from '../../../common/ui/preloader';
import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import Menu from '../../../menu/menu';
import { useLocation } from 'react-router-dom';
import FlowersMenu from './flowers-menu';
import PlantsHome from './plants/plants-home';
import PlantTypesHome from './plant-types/types-home';
import PlantLocationsHome from './plant-locations/locations-home';
import WaterLogsHome from './water-logs/water-logs-home';
import PlantWateringCalendarHome from './plant-calendar/plant-calendar-home';

const FlowersHome = () => {

    const { loading } = useAuthStatus();

    const { pathname } = useLocation();

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                        <FlowersMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 pb-10">
                            <div className="xl:max-w-[1100px] max-w-full mx-auto py-10">
                                {
                                    pathname === '/flowers' && <WaterLogsHome />
                                }

                                {
                                    pathname === '/flowers/plants' && <PlantsHome />
                                }

                                {
                                    pathname === '/flowers/types' && <PlantTypesHome />
                                }

                                {
                                    pathname === '/flowers/locations' && <PlantLocationsHome />
                                }

                                {
                                    pathname.startsWith('/flowers/plant-calendar/') && <PlantWateringCalendarHome />
                                }
                            </div>

                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default FlowersHome;