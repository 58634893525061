import { useState, MouseEvent } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../common/ui/actions';
import { DeleteIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { IPlantAttachment } from '../../flowers-interfaces';
import { useFlowersStore } from '../../data/flowers-store';
import { deletePlantAttachment } from '../../data/flowers-data-service';

interface IDeletePlantAttachmentButton {
    attachment: IPlantAttachment;
}

const DeletePlantAttachmentButton = ({ attachment }: IDeletePlantAttachmentButton) => {

    const [isLoading, setLoading] = useState(false);

    const setPlantsData = useFlowersStore(state => state.setPlantsData);

    const deleteAttachmentHandler = async (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        setLoading(true);
        const response = await deletePlantAttachment(attachment.id);
        setLoading(false);

        if(!response?.isValid) {
            showToast('Delete plant attachment error.');
            return;
        }

        setPlantsData(response);
        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ deleteAttachmentHandler }
            type="button"
            title="Delete Plant Attachment">

            {
                !isLoading &&
                <DeleteIcon size={ 20 } classes="pointer-events-none" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } />
            }
        </button>
    )
};

export default DeletePlantAttachmentButton;