import { ArrowRightIcon } from '../../../../../../common/ui/icons';

interface INextYearButton {
    year: number;
    setYear: (year: number) => void;
}

const NextYearButton = ({ year, setYear }: INextYearButton) => {

    const jumpToNextMonth = () => {
        setYear(year + 1);
    };

    return (
        <button
            title="Next Year"
            onClick={ jumpToNextMonth }
            type="button">
            <ArrowRightIcon />
        </button>
    )
};

export default NextYearButton;