import {
    eachDayOfInterval,
    eachMonthOfInterval,
    endOfYear,
    startOfYear,
    startOfMonth,
    endOfMonth,
    format,
    isToday,
    getDay, startOfDay
} from 'date-fns';
import {
    IPlantWaterCalendarDay,
    IPlantWaterCalendarMonth,
    IPlantWaterCalendarYear,
    IWaterLog
} from '../flowers-interfaces';

export const createPlantWaterCalendar = (plant_id: number, year: number, logs: IWaterLog[]) : IPlantWaterCalendarYear => {

    const result: IPlantWaterCalendarYear = {
        year,
        months: [],
    };

    const plantLogs = logs?.filter(item => item.plant_id === plant_id);
    const dayLogsMap = new Map<number, boolean>();

    for(const log of plantLogs) {
        const dayStart = startOfDay(log.date_time);
        dayLogsMap.set(dayStart.getTime(), true);
    }

    const date = new Date(year, 0, 1);

    const months: Date[] = eachMonthOfInterval({
        start: startOfYear(date),
        end: endOfYear(date),
    });

    for(const monthStartDate of months) {

        const calendarMonth: IPlantWaterCalendarMonth = {
            id: `${ year }-${ monthStartDate.getTime() }`,
            monthStartDate,
            monthName: format(monthStartDate, 'MMMM yyyy'),
            days: [],
            startDayOfWeek: getDay(monthStartDate), // 0 (Sunday) to 6 (Saturday)
        };

        const days = eachDayOfInterval({
            start: startOfMonth(monthStartDate),
            end: endOfMonth(monthStartDate),
        });

        for(const dayStartDate of days) {

            const day = startOfDay(dayStartDate).getTime();

            const calendarDay: IPlantWaterCalendarDay = {
                id: `${ year }-${ monthStartDate.getTime() }-${ dayStartDate.getTime() }`,
                dayName: format(dayStartDate, 'd'),
                dayStartDate,
                isTodayDay: isToday(dayStartDate),
                hasWatering: dayLogsMap.has(day),
            };

            calendarMonth.days.push(calendarDay);
        }

        result.months.push(calendarMonth);
    }

    return result;
};