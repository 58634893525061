import { ChangeEvent, PointerEvent as ReactPointerEvent, useEffect, useRef, useState } from 'react';
import {
    DiagramRectangleWithText,
    getTextAlignClassesByType
} from '../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { TodoDiagramUseStore } from '../../../data/store';
import RectangleSelection from './selection/rectangle-selection';
import {
    findAttachedLines,
    getGuideLinesForRect,
    IConnectionPoint,
    mapToSvgCoordinates,
    resizeSvg,
    tryGetSvgElement, updateHistory
} from '../../../domain/todo-diagram-provider';
import { MouseEvent as ReactMouseEvent } from 'react';

interface ISvgRectangle {
    useStore: TodoDiagramUseStore;
    shape: DiagramRectangleWithText;
}

enum SvgRectangleWithTextMode {
    Default = 0,
    EditText = 1,
}

const SvgRectangleWithText = ({ useStore, shape }: ISvgRectangle) => {

    const selectedElementId = useStore(state => state.selectedElementId);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);
    const dragArrowCollidingRectId = useStore(state => state.dragArrowCollidingRectId);
    const isSelected = selectedElementId === shape?.id || dragArrowCollidingRectId === shape?.id;

    const [mode, setMode] = useState<SvgRectangleWithTextMode>(SvgRectangleWithTextMode.Default);
    const [text, setText] = useState(shape?.text ?? '');

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === shape.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const isDraggingRef = useRef(false);
    const dragOffsetXRef = useRef(0);
    const dragOffsetYRef = useRef(0);
    const foreignObjectRef = useRef<SVGForeignObjectElement>(null);
    const editableRef = useRef<HTMLDivElement>(null);
    const connectedLinesRef = useRef<IConnectionPoint[]>([]);

    const onClick = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
        setSelectedElementId(shape.id);

        if(evt.detail === 2) {
            setMode(SvgRectangleWithTextMode.EditText);

            // Wait for the next render cycle to ensure the element is editable.
            setTimeout(() => {
                if(!editableRef.current) return;

                const range = document.createRange();
                range.selectNodeContents(editableRef.current);

                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);
            }, 0);
        }
    };

    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent) => {
            const target = evt.target as Node;
            if (foreignObjectRef.current?.contains(target)) return;
            setMode(SvgRectangleWithTextMode.Default);
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setText(shape?.text ?? '');
    }, [shape?.text]);

    const onDrag = (evt: PointerEvent) => {
        if (!isDraggingRef?.current) return;

        const $svg = tryGetSvgElement(evt.target);
        if(!$svg) return;

        const transformedPoint = mapToSvgCoordinates($svg, evt.clientX, evt.clientY);

        // Update the rectangle's position
        const newX = transformedPoint.x - dragOffsetXRef.current;
        const newY = transformedPoint.y - dragOffsetYRef.current;

        // Drag connected lines.
        for(const item of connectedLinesRef.current) {

            if(item.isStart) {
                item.lineOrArrow.x1 = newX + item.xDiff;
                item.lineOrArrow.y1 = newY + item.yDiff;
            }
            else{
                item.lineOrArrow.x2 = newX + item.xDiff;
                item.lineOrArrow.y2 = newY + item.yDiff;
            }
        }


        const updatedShape = shape.clone();
        updatedShape.x = newX;
        updatedShape.y = newY;

        let copy = data.clone();
        copy.children[foundIndex] = updatedShape;

        // Guide lines --------------.
        copy.removeGuideLines();
        const guideLines = getGuideLinesForRect(updatedShape, copy) || [];
        for(const line of guideLines) {
            copy.children.push(line);
        }

        // Should SVG be resized?
        copy = resizeSvg(
            data,
            copy,
            newX,
            newY,
            shape
        );

        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGRectElement>) => {

        evt.preventDefault();

        if(foundIndex === -1) return;

        isDraggingRef.current = true;

        const $svg = tryGetSvgElement(evt.currentTarget);
        if(!$svg) return;

        const transformedPoint = mapToSvgCoordinates($svg, evt.clientX, evt.clientY);

        connectedLinesRef.current = findAttachedLines(data, shape);

        dragOffsetXRef.current = transformedPoint.x - shape.x;
        dragOffsetYRef.current = transformedPoint.y - shape.y;

        const onPointerMove = (moveEvent: PointerEvent) => {
            onDrag(moveEvent);
        };

        const onPointerUp = () => {
            isDraggingRef.current = false;
            connectedLinesRef.current = [];

            // Remove all guidelines.
            const copy = data.clone();
            copy.removeGuideLines();
            setData(copy);

            setDataStack(updateHistory(dataStack, data));

            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
    };

    const onTextChange = (evt: ChangeEvent<HTMLDivElement>) => {
        const updatedText = (evt.target as HTMLDivElement).innerHTML;
        setText(updatedText);
    };

    const onTextBlur = () => {
        const updatedShape = shape.clone();
        updatedShape.text = text;

        const copy = data.clone();
        copy.children[foundIndex] = updatedShape;
        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
    };

    return (
        <g data-type="rectangle">
            <rect
                className="cursor-move"
                onClick={ onClick }
                onPointerDown={ onPointerDown }
                x={ shape.x }
                y={ shape.y }
                width={ shape.width }
                height={ shape.height }
                fill={ shape.fill ?? DiagramRectangleWithText.defaultFill }
                stroke={ shape.stroke ?? DiagramRectangleWithText.defaultStroke }
                strokeWidth={ shape.strokeWidth ?? DiagramRectangleWithText.defaultStrokeWidth }
                rx={ shape.rx }
                ry={ shape.ry }
            />

            <foreignObject
                ref={ foreignObjectRef }
                pointerEvents={ mode === SvgRectangleWithTextMode.EditText ? 'auto' : 'none' }
                x={ shape.x }
                y={ shape.y }
                width={ shape.width }
                height={ shape.height }>
                <div
                    ref={ editableRef }
                    className={ `flex flex-col ${ getTextAlignClassesByType(shape.textAlign) } w-full h-full p-2` }
                    contentEditable={ mode === SvgRectangleWithTextMode.EditText }
                    onInput={ onTextChange }
                    onBlur={ onTextBlur }
                    style={{
                        color: shape.textColor || DiagramRectangleWithText.defaultTextColor,
                        fontSize: shape.fontSize || DiagramRectangleWithText.defaultTextFontSize,
                        fontWeight: shape.isBold ? 'bold' : 'normal',
                        fontStyle: shape.isItalic ? 'italic' : 'normal',
                        textDecoration: shape.isUnderline ? 'underline' : 'none',
                    }} dangerouslySetInnerHTML={{__html: shape.text }}
                />
            </foreignObject>

            {
                isSelected &&
                <RectangleSelection
                    shape={ shape }
                    useStore={ useStore }
                />
            }
        </g>
    )
};

export default SvgRectangleWithText;