import { TodoDiagramUseStore } from '../../data/store';
import RectangleWithTextBtn from './elements/rectangle-with-text-btn';
import RoundedRectangleWithTextBtn from './elements/rounded-rectangle-with-text-btn';
import LineBtn from './elements/line-btn';
import ArrowBtn from './elements/arrow-btn';
import ToolbarDivider from './toolbar-divider';
import BgColorPicker from './properties/bg-color-picker';
import BorderColorPicker from './properties/border/border-color-picker';
import TextColorPicker from './properties/text/text-color-picker';
import SaveDiagramBtn from './actions/save-diagram-btn';
import CloseDiagramPreviewBtn from './actions/close-preview-btn';
import TextBtn from './elements/text-btn';
import DeleteSelectedElementBtn from './actions/delete-selected-element-btn';
import PredefinedStyleDropDown from './properties/predefined-style-drop-down';
import SvgBgColorPicker from './svg-bg-color-picker';

export interface IToolbarProps {
    useStore: TodoDiagramUseStore;
}

const Toolbar = ({ useStore }: IToolbarProps) => {

    return (
        <div className="bg-slate-100 px-4 py-2 text-sm flex items-center border-b border-slate-200">
            <RectangleWithTextBtn useStore={ useStore } />
            <RoundedRectangleWithTextBtn useStore={ useStore } />
            <TextBtn useStore={ useStore } />
            <LineBtn useStore={ useStore } />
            <ArrowBtn useStore={ useStore } />

            <ToolbarDivider />

            <SvgBgColorPicker useStore={ useStore } />
            <PredefinedStyleDropDown useStore={ useStore } />
            <BgColorPicker useStore={ useStore } />
            <BorderColorPicker useStore={ useStore } />
            <TextColorPicker useStore={ useStore } />

            <div className="ml-auto" />
            <DeleteSelectedElementBtn useStore={ useStore } />
            <SaveDiagramBtn useStore={ useStore } />
            <CloseDiagramPreviewBtn useStore={ useStore } />
        </div>
    );
};

export default Toolbar;