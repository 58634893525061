import MonthView from './month-view';
import { IFoodCalendarYear } from '../../food-interfaces';

interface IYearViewProps {
    calendarData: IFoodCalendarYear|null;
}

const YearView = ({ calendarData }: IYearViewProps) => {

    return (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 border-b border-r mt-4">
            {
                calendarData?.months?.map(month => {
                    return (
                        <MonthView
                            key={ month.id }
                            month={ month }
                        />
                    )
                })
            }
        </div>
    )
};

export default YearView;