import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getPlantLocationsData } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';
import PlantLocationsList from './list/plant-locations-list';
import PlantTypeTabsNavigation, { PlantLocationsTab } from './tabs-navigation';
import AddNewPlantLocation from './actions/add-new-plant-location';

const PlantLocationsHome = () => {

    const plantLocationsTab = useFlowersStore(store => store.plantLocationsTab);
    const setPlantLocationsData = useFlowersStore(store => store.setPlantLocationsData);

    useEffect(() => {
        document.title = `Plant Locations | Flowers`;
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getPlantLocationsData();

            if(!response) {
                showToast('Get plant locations error.');
                return;
            }

            setPlantLocationsData(response);
        })();
    }, [setPlantLocationsData]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Plant Locations
            </h1>

            <PlantTypeTabsNavigation />

            {
                plantLocationsTab === PlantLocationsTab.Default &&
                <PlantLocationsList />
            }

            {
                plantLocationsTab === PlantLocationsTab.AddNew &&
                <AddNewPlantLocation />
            }
        </div>
    )
};

export default PlantLocationsHome;