import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { useTodoTitleChartStore } from '../data/todo-title-chart-store';

const Title = () => {

    const chartData = useTodoTitleChartStore(store => store.chartData);

    return (
        <h1 className="text-xl xl:text-3xl flex items-center mb-6">
            <div dangerouslySetInnerHTML={{__html: markdown2Html(chartData?.parentTodo?.todo_title) }} />
        </h1>
    )
};

export default Title;