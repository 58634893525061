import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getPlantTypesData } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';
import PlantTypesList from './list/plant-types-list';
import PlantTypeTabsNavigation, { PlantTypeTab } from './tabs-navigation';
import AddNewPlantType from './actions/add-new-plant-type';

const PlantTypesHome = () => {

    const plantTypeTab = useFlowersStore(store => store.plantTypeTab);
    const setPlantTypesData = useFlowersStore(store => store.setPlantTypesData);

    useEffect(() => {
        document.title = `Plant Types | Flowers`;
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getPlantTypesData();

            if(!response) {
                showToast('Get plant types error.');
                return;
            }

            setPlantTypesData(response);
        })();
    }, [setPlantTypesData]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Plant Types
            </h1>

            <PlantTypeTabsNavigation />

            {
                plantTypeTab === PlantTypeTab.Default &&
                <PlantTypesList />
            }

            {
                plantTypeTab === PlantTypeTab.AddNew &&
                <AddNewPlantType />
            }

        </div>
    )
};

export default PlantTypesHome;