import { useState } from 'react';
import Todo from '../todo/todo';
import { PlusIcon } from '../../../../common/ui/icons';
import NewTodo from '../todo/todo-add';
import { ICombinedSection } from '../../planner-interfaces';
import TodoDragTarget from '../todo/drag/todo-drag-target';
import { canAddTasks, isSpecialProjectType, shouldHideTitles } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';
import { getTodosFilteredByTags } from '../../domain/tags-provider';

enum AddTodoMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface ISectionTodosProps {
    combinedSection: ICombinedSection;
    project_id: number;
    titleVisible: boolean;
}

const SectionTodos = (props: ISectionTodosProps) => {

    const listType = usePlannerStore(state => state.listType);
    const selectedFilterTags = usePlannerStore(state => state.selectedFilterTags);
    const _projectHideTitles = usePlannerStore(store => store.projectHideTitles);
    const projectHideTitles = shouldHideTitles(_projectHideTitles, listType, selectedFilterTags);

    const isSpecial = isSpecialProjectType(listType);

    const shouldHideProjectTitles = isSpecial && projectHideTitles;

    const { combinedSection, project_id, titleVisible } = props;
    const { section } = combinedSection;

    const [addTodoMode, setAddTodoMode] = useState(AddTodoMode.DEFAULT);

    const startAddingTodo = () => {
        setAddTodoMode(AddTodoMode.ADDING);
    };

    const stopAddingTodo = () => {
        setAddTodoMode(AddTodoMode.DEFAULT);
    };

    const [_isApplied, combinedSectionTodos] = getTodosFilteredByTags(combinedSection.todos, listType, selectedFilterTags);
    const isSectionEmpty = combinedSectionTodos.length <= 0;

    return (
        <>
            {
                (combinedSection.isOpened || !titleVisible) &&
                <div className={ titleVisible ? 'ml-6' : '' }>
                    {
                        combinedSectionTodos.map((todo, index) =>
                            <Todo
                                todo={ todo }
                                key={ `todo-${ todo.todo_id}` }
                                isFirst={ index === 0 }
                                isLast={ projectHideTitles || (index === combinedSectionTodos.length - 1) }
                                nestLevel={ 0 }
                                showTodoLink={ isSpecial }
                            />
                        )
                    }

                    {
                        isSectionEmpty &&
                        <TodoDragTarget
                            targetProjectId={ project_id }
                            targetTodoId={ 0 }
                            targetOrder={ 0 }
                            isBefore={ false }
                            isFirstSectionTodo={ true }
                            section_id={ section.section_id }
                            targetAsParent={ false }
                        />
                    }

                    {
                        addTodoMode === AddTodoMode.DEFAULT && !shouldHideProjectTitles && canAddTasks(listType) &&
                        <button
                            className={ `section__add-task flex items-center text-sm text-slate-400 ${ isSectionEmpty ? 'mt-4' : '' }` }
                            onClick={ startAddingTodo }
                            type="button">
                            <div className="mr-2"><PlusIcon /></div>
                            <div>Add Task</div>
                        </button>
                    }

                    {
                        addTodoMode === AddTodoMode.ADDING &&
                        <NewTodo
                            project_id={ project_id }
                            section_id={ section.section_id }
                            order={
                                isSectionEmpty ?
                                    1 :
                                    combinedSection.todos[combinedSection.todos.length - 1].todo_order + 1 }
                            close={ stopAddingTodo }
                        />
                    }

                </div>
            }
        </>
    )
};

export default SectionTodos;