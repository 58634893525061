import { create } from 'zustand';
import { IFlowersResponse, IPlantLocation, IPlantType } from '../flowers-interfaces';
import { PlantTypeTab } from '../ui/plant-types/tabs-navigation';
import { PlantLocationsTab } from '../ui/plant-locations/tabs-navigation';
import { PlantsTab } from '../ui/plants/tabs-navigation';

interface FlowersState {

    // -------- Plant Types -----------------------

    plantTypesData: IPlantType[];
    setPlantTypesData: (_plantTypesData: IPlantType[]) => void;

    plantTypeTab: PlantTypeTab;
    setPlantTypeTab: (_plantTypeTab: PlantTypeTab) => void;

    // -------- Plant Locations -----------------------

    plantLocationsData: IPlantLocation[];
    setPlantLocationsData: (_plantLocationsData: IPlantLocation[]) => void;

    plantLocationsTab: PlantLocationsTab;
    setPlantLocationsTab: (_plantTypeTab: PlantLocationsTab) => void;

    // -------- Plants -----------------------

    plantsData: IFlowersResponse;
    setPlantsData: (_plantsData: IFlowersResponse) => void;

    plantsTab: PlantsTab;
    setPlantsTab: (_plantsTab: PlantsTab) => void;

    // -------- Water Logs -----------------------

    waterLogsData: IFlowersResponse;
    setWaterLogsData: (_waterLogsData: IFlowersResponse) => void;
}

export const useFlowersStore = create<FlowersState>()((set) => ({

    // -------- Plant Types -----------------------

    plantTypesData: [],
    setPlantTypesData: (_plantTypesData: IPlantType[]) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantTypesData: _plantTypesData,
            };
        });
    },

    plantTypeTab: PlantTypeTab.Default,
    setPlantTypeTab: (_plantTypeTab: PlantTypeTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantTypeTab: _plantTypeTab,
            };
        });
    },

    // -------- Plant Locations -----------------------

    plantLocationsData: [],
    setPlantLocationsData: (_plantLocationsData: IPlantLocation[]) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantLocationsData: _plantLocationsData,
            };
        });
    },

    plantLocationsTab: PlantLocationsTab.Default,
    setPlantLocationsTab: (_plantLocationsTab: PlantLocationsTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantLocationsTab: _plantLocationsTab,
            };
        });
    },

    // -------- Plants -----------------------

    plantsData: null,
    setPlantsData: (_plantsData: IFlowersResponse) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantsData: _plantsData,
            };
        });
    },

    plantsTab: PlantsTab.Default,
    setPlantsTab: (_plantsTab: PlantsTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantsTab: _plantsTab,
            };
        });
    },

    // -------- Water Logs -----------------------

    waterLogsData: null,
    setWaterLogsData: (_waterLogsData: IFlowersResponse) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                waterLogsData: _waterLogsData,
            };
        });
    },
}))