import { useFlowersStore } from '../../../data/flowers-store';
import { trackWaterLog } from '../../../data/flowers-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { endOfDay, startOfDay } from 'date-fns';
import { calculateNextWateringDate } from '../../../domain/flowers-provider';
import { IPlant } from '../../../flowers-interfaces';
import { getWeather } from '../../../domain/weather-provider';

interface ITrackBtn {
    plant: IPlant;
}

const TrackBtn = ({ plant }: ITrackBtn) => {

    const setWaterLogsData = useFlowersStore(store => store.setWaterLogsData);

    const onClick = async () => {

        const now = startOfDay(new Date());
        const today_end = endOfDay(now).getTime();

        const next_watering = calculateNextWateringDate(
            now.getTime(), // lastWatered
            plant.plantType,
            plant.location,
            getWeather(),
        );

        const response = await trackWaterLog(
            today_end,
            plant.plant_id,
            now.getTime(),
            next_watering,
            '',
        );

        if(!response) {
            showToast('Track water logs error.');
            return;
        }

        setWaterLogsData(response);
    };

    return (
        <button
            onClick={ onClick }
            className="bg-emerald-100 border border-emerald-500 rounded py-1 ml-6 my-2 flex items-center"
            type="button">
            <span className="ml-2 mr-6">✅</span>
            <span className="mr-10">Track</span>
        </button>
    )
};

export default TrackBtn;