import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import { IPlant } from '../../../flowers-interfaces';
import DeletePlant from '../actions/delete-plant';
import EditPlant from '../actions/edit-plant';
import PlantTypeColorIndicator from '../../plant-types/plant-type-color-indicator';
import PlantUploadAttachmentButton from '../../attachment/upload-btn';
import PlantAttachment from '../../attachment/attachment';
import PlantLifecycleText from '../../plant-life-cycle/plant-life-cycle-text';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';
import PlantLocationText from '../../plant-locations/plant-location-text';
import { format } from 'date-fns';
import OpenPlantCalendarBtn from '../actions/open-plant-calendar';

interface IPlantProps {
    plant: IPlant;
}

enum Mode {
    Default = 0,
    Edit = 1,
}

const Plant = ({ plant }: IPlantProps) => {

    const [mode, setMode] = useState<Mode>(Mode.Default);

    const startEdit = () => {
        setMode(Mode.Edit);
    };

    const stopEdit = () => {
        setMode(Mode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === Mode.Default &&
                (
                    <>
                        <div className="flex items-center">

                            <PlantTypeColorIndicator plantType={ plant.plantType }/>

                            <button
                                className={ plant.is_alive ? '' : 'line-through' }
                                type="button"
                                onClick={ startEdit }>
                                { plant.plant_name }
                            </button>

                            {
                                plant.quantity > 1 &&
                                <div className="mx-4 text-xs">({ plant.quantity })</div>
                            }

                            <div className="flex items-center ml-auto">
                                {
                                    plant.location &&
                                    <>
                                        <PlantLocationText
                                            location={ plant.location }
                                        />

                                        {
                                            !!plant.location.is_indoor &&
                                            <div className="mx-4">🏚️</div>
                                        }
                                    </>
                                }

                                <PlantLifecycleText
                                    plantLifeCycleType={ plant.plant_life_cycle_type }
                                />
                            </div>

                            <Actions classes="plant-type__actions" mr="mr-0" ml="ml-4" gridSize={ 2 }>
                                <DeletePlant plant={ plant }/>

                                <button
                                    title="Edit Plant"
                                    className="edit-plant-type flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 }/>
                                </button>

                                <PlantUploadAttachmentButton
                                    plant={ plant }
                                />

                                <OpenPlantCalendarBtn
                                    plant={ plant }
                                />
                            </Actions>
                        </div>

                        <div className="flex items-center">

                            <div className="ml-6 mr-2">
                                <OpenPlantCalendarBtn plant={ plant }/>
                            </div>

                            <div
                                className="text-slate-900 bg-indigo-50 rounded-md px-6 py-1 my-2 inline-flex items-center justify-center">
                                {
                                    plant.next_watering &&
                                    <button
                                        type="button"
                                        onClick={ startEdit }>
                                        <strong className="mr-2">Next watering:</strong>
                                        <span>{ format(new Date(plant.next_watering), 'dd MMM, yyyy') }</span>
                                    </button>
                                }
                                {
                                    !plant.next_watering &&
                                    <button
                                        type="button"
                                        onClick={ startEdit }>
                                        No watering is scheduled
                                    </button>
                                }
                            </div>

                            <div className="mx-4 text-sm">
                                Every { plant.waterFrequency } { plant.waterFrequency === 1 ? 'day' : 'days' }
                            </div>

                        </div>

                        {
                            plant.plant_desc &&
                            <div
                                className="markdown-editor my-2 ml-6 px-4 pt-2 text-slate-500 border border-slate-300 rounded bg-slate-50"
                                dangerouslySetInnerHTML={ { __html: markdown2Html(plant.plant_desc) } }
                            />
                        }

                        {
                            plant.attachments?.length > 0 &&
                            <div className="flex flex-wrap items-center ml-6">
                                {
                                    plant.attachments.map(attachment => {
                                        return (
                                            <PlantAttachment
                                                key={ attachment.id }
                                                attachment={ attachment }
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    </>
                )
            }

            {
                mode === Mode.Edit &&
                <EditPlant
                    plant={ plant }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default Plant;