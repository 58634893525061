import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { IPlant } from '../../../flowers-interfaces';
import PlantTypeColorIndicator from '../../plant-types/plant-type-color-indicator';
import PlantAttachment from '../../attachment/attachment';
import PlantLifecycleText from '../../plant-life-cycle/plant-life-cycle-text';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';
import PlantLocationText from '../../plant-locations/plant-location-text';
import EditPlant from '../../plants/actions/edit-plant';
import DeletePlant from '../../plants/actions/delete-plant';
import { EditIcon } from '../../../../../common/ui/icons';
import PlantUploadAttachmentButton from '../../attachment/upload-btn';
import TrackBtn from '../actions/track-btn';

interface IPlantProps {
    plant: IPlant;
}

enum Mode {
    Default = 0,
    Edit = 1,
}

const WaterLog = ({ plant }: IPlantProps) => {

    const [mode, setMode] = useState<Mode>(Mode.Default);

    const startEdit = () => {
        setMode(Mode.Edit);
    };

    const stopEdit = () => {
        setMode(Mode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === Mode.Default &&
                (
                    <>
                        <div className="flex items-center">

                            <PlantTypeColorIndicator plantType={ plant.plantType }/>

                            <button
                                className={ plant.is_alive ? '' : 'line-through' }
                                type="button"
                                onClick={ startEdit }>
                                { plant.plant_name }
                            </button>

                            {
                                plant.quantity > 1 &&
                                <div className="mx-4 text-xs">({ plant.quantity })</div>
                            }

                            <div className="flex items-center ml-auto">
                                {
                                    plant.location &&
                                    <>
                                        <PlantLocationText
                                            location={ plant.location }
                                        />

                                        {
                                            !!plant.location.is_indoor &&
                                            <div className="mx-4">🏚️</div>
                                        }
                                    </>
                                }

                                <PlantLifecycleText
                                    plantLifeCycleType={ plant.plant_life_cycle_type }
                                />
                            </div>

                            <Actions classes="plant-type__actions" mr="mr-0" ml="ml-4" gridSize={ 3 }>
                                <DeletePlant plant={ plant }/>

                                <button
                                    title="Edit Plant"
                                    className="edit-plant-type flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 }/>
                                </button>

                                <PlantUploadAttachmentButton
                                    plant={ plant }
                                />
                            </Actions>
                        </div>

                        <TrackBtn
                            plant={ plant }
                        />

                        {
                            plant.plant_desc &&
                            <div className="markdown-editor my-2 ml-6 px-4 pt-2 text-slate-500 border border-slate-300 rounded bg-slate-50"
                                 dangerouslySetInnerHTML={ { __html: markdown2Html(plant.plant_desc) } }
                            />
                        }

                        {
                            plant.attachments?.length > 0 &&
                            <div className="flex flex-wrap items-center ml-6">
                                {
                                    plant.attachments.map(attachment => {
                                        return (
                                            <PlantAttachment
                                                key={ attachment.id }
                                                attachment={ attachment }
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    </>
                )
            }

            {
                mode === Mode.Edit &&
                <EditPlant
                    plant={ plant }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default WaterLog;