import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IPlantLocation } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { deletePlantLocation } from '../../../data/flowers-data-service';

interface IDeletePlantLocation {
    plantLocation: IPlantLocation;
}

const DeletePlantLocation = ({ plantLocation }: IDeletePlantLocation) => {

    const [isLoading, setLoading] = useState(false);

    const setPlantLocationsData = useFlowersStore(store => store.setPlantLocationsData);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete the plant location "${ plantLocation.location_name }"?`)) return;

        setLoading(true);
        const response = await deletePlantLocation(plantLocation.location_id);
        setLoading(false);

        if(!response) {
            showToast('Delete plant location error.');
            return;
        }

        setPlantLocationsData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Plant Location"
                    className="plant-location__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeletePlantLocation;