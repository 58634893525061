import { create } from 'zustand';
import { ITodoTitleChartData } from '../todo-title-chart-interfaces';

interface TodoTitleChartState {
    chartData: ITodoTitleChartData|null;
    setChartData: (_chartData: ITodoTitleChartData|null) => void;
}

export const useTodoTitleChartStore = create<TodoTitleChartState>()((set) => ({

    chartData: null,
    setChartData: (_chartData: ITodoTitleChartData|null) => {
        return set((state: TodoTitleChartState) => {
            return {
                ...state,
                chartData: _chartData,
            };
        });
    },
}))