import { useEffect } from 'react';
import WaterLogsList from './list/water-logs-list';

const WaterLogsHome = () => {

    useEffect(() => {
        document.title = `Water Logs | Flowers`;
    }, []);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Water Logs
            </h1>

            <WaterLogsList />
        </div>
    )
};

export default WaterLogsHome;