import { PlantLifeCycle } from '../../domain/flowers-provider';
import { ChangeEvent } from 'react';

interface IPlantLifeCycleSelect {
    value: PlantLifeCycle;
    onSelect: (_value: PlantLifeCycle) => void;
}

const PlantLifeCycleSelect = ({ value, onSelect }: IPlantLifeCycleSelect) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _value = Number(evt.target.value) || PlantLifeCycle.Perennial;
        onSelect(_value as PlantLifeCycle);
    };

    return (
        <select
            className="border rounded px-4 py-2"
            value={ value }
            onChange={ onChange }>
            <option value={ PlantLifeCycle.Annual }>
                Однолетнее (живет один сезон)
            </option>
            <option value={ PlantLifeCycle.Biennial }>
                Двухлетнее (живет два года)
            </option>
            <option value={ PlantLifeCycle.Perennial }>
                Многолетнее (живет более двух лет)
            </option>
            <option value={ PlantLifeCycle.Ephemeral }>
                Эфемерное (короткий жизненный цикл, несколько недель)
            </option>
            <option value={ PlantLifeCycle.Herbaceous }>
                Травянистое (отмирает до уровня земли каждый сезон)
            </option>
        </select>
    )
};

export default PlantLifeCycleSelect;