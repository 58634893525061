import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useFoodStore } from '../../../data/food-store';
import { FoodCategoriesTab } from '../tabs-navigation';
import { insertFoodCategory } from '../../../data/food-data-service';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';

const AddNewFoodCategory = () => {

    const nameRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const setFoodCategoriesData = useFoodStore(store => store.setFoodCategoriesData);
    const setFoodCategoriesTab = useFoodStore(store => store.setFoodCategoriesTab);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    const save = useCallback(async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        setLoading(true);

        const description = editorRef.current?.getMarkdown() || '';

        const response = await insertFoodCategory(
            name,
            description,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert food category error.');
            return;
        }

        setFoodCategoriesData(response);
        setFoodCategoriesTab(FoodCategoriesTab.Default);
    }, [
        name,
        setFoodCategoriesData,
        setFoodCategoriesTab,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                const $box = (evt.target as HTMLElement).closest('[data-type="add-food-category"]');
                if(!$box) return;

                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div data-type="add-food-category">
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Food Category Name</div>
                <input
                    ref={nameRef}
                    value={name}
                    onInput={onNameChange}
                    onKeyDown={onNameKeyDown}
                    className={`border rounded px-4 py-2 ${isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200'}`}
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={editorRef}
                    markdown={''}
                    plugins={getCompactMarkdownEditorPlugins()}
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={24} color={'#717985'}/>
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={save}
                        type="button">Save</button>
                }
            </div>

        </div>
    )
};

export default AddNewFoodCategory;