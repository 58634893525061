import { TodoDiagramUseStore } from '../../../../data/store';
import { ChangeEvent } from 'react';
import { DiagramLine } from '../../../../domain/diagram-elements/elements/diagram-line';
import { updateHistory } from '../../../../domain/todo-diagram-provider';
import BorderStyleDropDown from './border-style-drop-down';

interface IBorderColorPicker {
    useStore: TodoDiagramUseStore;
}

const BorderColorPicker = ({ useStore }: IBorderColorPicker) => {

    const selectedElementId = useStore(state => state.selectedElementId);
    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const selectedElement = foundIndex >= 0 ? data.children[foundIndex] : null;

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {

        if(foundIndex === -1) return;

        const copy = data.clone();

        const element = selectedElement as DiagramLine;
        element.stroke = evt.target.value;
        copy.children[foundIndex] = element;

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
    };

    return (
        <>
            {
                selectedElement &&
                <label className="relative flex items-center text-slate-500 text-xs bg-white px-2 py-1 border rounded mr-4">
                    <BorderStyleDropDown useStore={ useStore } />
                    <input
                        type="color"
                        className="w-4 h-4"
                        value={ (selectedElement as DiagramLine).stroke }
                        onChange={ onChange }
                    />
                </label>
            }
        </>
    )
};

export default BorderColorPicker;