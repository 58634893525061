import { NavLink } from 'react-router-dom';
import { closeMobileMenu } from '../../../menu/ui/mobile-menu-provider';

const FoodMenu = () => {

    return (
        <div className="side-menu flex flex-col xl:w-[250px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3">

                <div className="text-sm pb-3 pt-3 text-slate-400">Food</div>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/food` }
                    end={ true }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(209, 195, 84)',
                        }}
                    />
                    <p>Food Log</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/food/food-items` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(191,135,51)',
                        }}
                    />
                    <p>Food Items</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/food/categories` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(191, 90, 51)',
                        }}
                    />
                    <p>Categories</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/food/calendar` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(51, 165, 191)',
                        }}
                    />
                    <p>Calendar</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/food/export` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(180, 51, 191)',
                        }}
                    />
                    <p>Export</p>
                </NavLink>

            </div>
        </div>
    )
};

export default FoodMenu;