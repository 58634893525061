import {
    getCollidingRectangle, ICollidingRectangleResult,
    mapToSvgCoordinates,
    SELECTION_CIRCLE_COLOR, SELECTION_CIRCLE_HOVER_FILL, SELECTION_CIRCLE_HOVER_STROKE,
    SELECTION_CIRCLE_RADIUS,
    updateHistory
} from '../../../../domain/todo-diagram-provider';
import { MouseEvent, PointerEvent as ReactPointerEvent, useRef, useState } from 'react';
import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramLine } from '../../../../domain/diagram-elements/elements/diagram-line';
import { DiagramArrow } from '../../../../domain/diagram-elements/elements/diagram-arrow';
import { DiagramElementType } from '../../../../domain/diagram-elements/diagram-element-type-enum';
import { DiagramElement } from '../../../../domain/diagram-elements/diagram-element';
import {
    DiagramRectangleWithText,
} from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';

export enum LineSelectionCirclePointType {
    Start = 0,
    End = 1,
}

interface ILineSelectionCircleProps {
    type: LineSelectionCirclePointType;
    cx: number;
    cy: number;
    useStore: TodoDiagramUseStore;
    shape: DiagramLine|DiagramArrow;
}

const LineSelectionCircle = ({ type, cx, cy, useStore, shape }: ILineSelectionCircleProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === shape.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const setDragArrowCollidingRectId = useStore(state => state.setDragArrowCollidingRectId);
    const setDragArrowCollidingCircleId = useStore(state => state.setDragArrowCollidingCircleId);

    const [isSelected, setIsSelected] = useState(false);

    const collideResultRef = useRef<ICollidingRectangleResult|null>(null);
    const rectanglesRef = useRef<DiagramElement[]>([]);

    const onClick = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    const getUpdatedShape = (newX: number, newY: number) => {
        const updatedShape = shape.clone() as DiagramLine|DiagramArrow;

        switch (type) {
            case LineSelectionCirclePointType.Start: {
                updatedShape.x1 = newX;
                updatedShape.y1 = newY;
                break;
            }
            case LineSelectionCirclePointType.End: {
                updatedShape.x2 = newX;
                updatedShape.y2 = newY;
                break;
            }
        }

        return updatedShape;
    };

    const onCircleDrag = (moveEvent: PointerEvent) => {
        if(foundIndex === -1) return;

        let svg: SVGSVGElement|null = null;

        if (moveEvent.target instanceof SVGSVGElement) {
            svg = moveEvent.target;
        }
        else{
            if(moveEvent.target instanceof SVGElement) {
                svg = moveEvent.target?.closest('svg') as SVGSVGElement;
            }
        }

        if(!svg) return;

        const { x: newX, y: newY } = mapToSvgCoordinates(svg, moveEvent.clientX, moveEvent.clientY);

        // Show selection circles of colliding rectangle when arrow is hovering it.
        // Change relevant selection circle color.
        const collideResult = getCollidingRectangle(newX, newY, (rectanglesRef.current || []) as DiagramRectangleWithText[]);
        const { collidingRect, collidingSelectionCircle } = collideResult;
        setDragArrowCollidingRectId(collidingRect?.id || null);
        setDragArrowCollidingCircleId(collidingSelectionCircle === null ? null : collidingSelectionCircle.index);
        setIsSelected(!!collidingSelectionCircle);
        collideResultRef.current = collideResult || null;

        const updatedShape = getUpdatedShape(newX, newY);
        const copy = data.clone();
        copy.children[foundIndex] = updatedShape;
        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGCircleElement>) => {
        evt.preventDefault();

        setIsSelected(false);
        collideResultRef.current = null;

        // Rectangles data is used to detect rectangles
        // that are colliding with the drag circle.
        rectanglesRef.current = data?.children?.filter(item => item.type === DiagramElementType.RectangleWithText ||
                                                  item.type === DiagramElementType.RoundedRectangleWithText) || [];

        const onPointerMove = (moveEvent: PointerEvent) => {
            onCircleDrag(moveEvent);
        };

        const onPointerUp = () => {

            if(collideResultRef.current?.collidingSelectionCircle) {

                // Move arrow to the center of the rectangle selection circle.
                const updatedShape = getUpdatedShape(
                    collideResultRef.current.collidingSelectionCircle.cx,
                    collideResultRef.current.collidingSelectionCircle.cy
                );
                const copy = data.clone();
                copy.children[foundIndex] = updatedShape;
                setData(copy);
            }

            rectanglesRef.current = [];
            setDragArrowCollidingRectId(null);
            setDragArrowCollidingCircleId(null);

            setIsSelected(false);
            collideResultRef.current = null;

            setDataStack(updateHistory(dataStack, data));

            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp, false);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp, false);
    };

    return (
        <circle
            className="cursor-pointer"
            cx={ cx }
            cy={ cy }
            r={ SELECTION_CIRCLE_RADIUS }
            fill={ isSelected ? SELECTION_CIRCLE_HOVER_FILL : SELECTION_CIRCLE_COLOR }
            stroke={ isSelected ? SELECTION_CIRCLE_HOVER_STROKE : SELECTION_CIRCLE_COLOR }
            onPointerDown={ onPointerDown }
            onClick={ onClick }
        />
    )
};

export default LineSelectionCircle;