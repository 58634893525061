import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IPlant } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { deletePlant } from '../../../data/flowers-data-service';

interface IDeletePlant {
    plant: IPlant;
}

const DeletePlant = ({ plant }: IDeletePlant) => {

    const [isLoading, setLoading] = useState(false);

    const setPlantsData = useFlowersStore(store => store.setPlantsData);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete the plant "${ plant.plant_name }"?`)) return;

        setLoading(true);
        const response = await deletePlant(plant.plant_id);
        setLoading(false);

        if(!response) {
            showToast('Delete plant error.');
            return;
        }

        setPlantsData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Plant"
                    className="plant__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeletePlant;