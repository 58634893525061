import { useEffect, useState, MouseEvent as ReactMouseEvent } from 'react';
import { TodoDiagramUseStore } from '../../../data/store';
import { PhotoIcon } from '../../../../../common/ui/icons';
import { DiagramRectangleWithText } from '../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { DiagramLine } from '../../../domain/diagram-elements/elements/diagram-line';
import { updateHistory } from '../../../domain/todo-diagram-provider';

interface IPredefinedStyleDropDown {
    useStore: TodoDiagramUseStore;
}

const rectStyleMap = new Map<string, { fill: string; stroke: string }>([
    ['bg-violet-200 border-black', { fill: 'rgb(221 214 254)', stroke: '#000' }],
    ['bg-emerald-200 border-black', { fill: 'rgb(167 243 208)', stroke: '#000' }],
    ['bg-sky-200 border-black', { fill: 'rgb(186 230 253)', stroke: '#000' }],
    ['bg-cyan-200 border-black', { fill: 'rgb(165 243 252)', stroke: '#000' }],
    ['bg-blue-200 border-black', { fill: 'rgb(191 219 254)', stroke: '#000' }],
    ['bg-indigo-200 border-black', { fill: 'rgb(199 210 254)', stroke: '#000' }],
    ['bg-teal-100 border-black', { fill: 'rgb(204 251 241)', stroke: '#000' }],
    ['bg-purple-200 border-black', { fill: 'rgb(233 213 255)', stroke: '#000' }],
    ['bg-lime-200 border-black', { fill: 'rgb(217 249 157)', stroke: '#000' }],
    ['bg-yellow-200 border-black', { fill: 'rgb(254 249 195)', stroke: '#000' }],
    ['bg-amber-200 border-black', { fill: 'rgb(253 230 138)', stroke: '#000' }],
    ['bg-orange-200 border-black', { fill: 'rgb(255 237 213)', stroke: '#000' }],
    ['bg-slate-200 border-black', { fill: 'rgb(226 232 240)', stroke: '#000' }],
    ['bg-neutral-200 border-black', { fill: 'rgb(229 229 229)', stroke: '#000' }],
    ['bg-green-200 border-black', { fill: 'rgb(187 247 208)', stroke: '#000' }],
    ['bg-white border-black', { fill: '#fff', stroke: '#000' }],
    ['bg-red-200 border-black', { fill: 'rgb(254 202 202)', stroke: '#000' }],
    ['bg-fuchsia-200 border-black', { fill: 'rgb(245 208 254)', stroke: '#000' }],
    ['bg-pink-200 border-black', { fill: 'rgb(251 207 232)', stroke: '#000' }],
    ['bg-rose-200 border-black', { fill: 'rgb(254 205 211)', stroke: '#000' }],
]);

const lineStyleMap = new Map<string, { stroke: string }>([
    ['bg-black border-black', { stroke: '#000' }],
    ['bg-blue-700 border-black', { stroke: 'rgb(29 78 216)' }],
    ['bg-indigo-700 border-black', { stroke: 'rgb(67 56 202)' }],
    ['bg-purple-700 border-black', { stroke: 'rgb(126 34 206)' }],

    ['bg-violet-700 border-black', { stroke: 'rgb(124 58 237)' }],
    ['bg-yellow-700 border-black', { stroke: 'rgb(161 98 7)' }],
    ['bg-cyan-700 border-black', { stroke: 'rgb(14 116 144)' }],
    ['bg-sky-700 border-black', { stroke: 'rgb(3 105 161)' }],

    ['bg-emerald-700 border-black', { stroke: 'rgb(4 120 87)' }],
    ['bg-teal-700 border-black', { stroke: 'rgb(15 118 110)' }],
    ['bg-green-700 border-black', { stroke: 'rgb(21 128 61)' }],
    ['bg-lime-700 border-black', { stroke: 'rgb(63 98 18)' }],

    ['bg-fuchsia-700 border-black', { stroke: 'rgb(168 32 203)' }],
    ['bg-pink-700 border-black', { stroke: 'rgb(190 24 93)' }],
    ['bg-red-700 border-black', { stroke: 'rgb(185 28 28)' }],
    ['bg-rose-700 border-black', { stroke: 'rgb(190 18 60)' }],

    ['bg-slate-700 border-black', { stroke: 'rgb(51 65 85)' }],
    ['bg-neutral-700 border-black', { stroke: 'rgb(82 82 82)' }],
    ['bg-orange-700 border-black', { stroke: 'rgb(194 65 12)' }],
]);

const PredefinedStyleDropDown = ({ useStore } : IPredefinedStyleDropDown) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const selectedElementId = useStore(state => state.selectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
    const selectedElement = foundIndex >= 0 ? data.children[foundIndex] : null;

    const [isOpened, setIsOpened] = useState(false);

    const isRectangle = selectedElement?.type === DiagramElementType.RoundedRectangleWithText ||
                        selectedElement?.type === DiagramElementType.RectangleWithText;

    const isLine = selectedElement?.type === DiagramElementType.Arrow ||
                    selectedElement?.type === DiagramElementType.Line;

    const toggle = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
        setIsOpened(_isOpened => !_isOpened);
    };

    const stopPropagation = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
    };

    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent) => {
            try{
                const element = evt.target as HTMLElement;
                const btn = element.closest('[data-type="predefined-styles-drop-down"]');
                if(btn) return;

                setIsOpened(false);
            }
            catch (ex) {
                console.log(ex);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const changeStyle = (style: string) => {
        const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
        if(foundIndex === -1) return;

        const copy = data.clone();

        if(isRectangle && rectStyleMap.has(style)) {
            const rect = selectedElement as DiagramRectangleWithText;
            const { fill, stroke } = rectStyleMap.get(style);
            rect.fill = fill;
            rect.stroke = stroke;
            copy.children[foundIndex] = rect;
        }

        if(isLine && lineStyleMap.has(style)) {
            const line = selectedElement as DiagramLine;
            const { stroke } = lineStyleMap.get(style);
            line.stroke = stroke;
            copy.children[foundIndex] = line;
        }

        setDataStack(updateHistory(dataStack, copy));
        setData(copy);
    };

    return (
        <>
            {
                selectedElement &&
                <div
                    data-type="predefined-styles-drop-down"
                    className="relative flex items-center mr-4"
                    onClick={ stopPropagation }>
                    <button
                        onClick={ toggle }
                        title="Predefined Styles"
                        type="button">
                        <PhotoIcon color={ '#000' } size={ 20 } strokeWidth={ 1.5 } fill={ '#fff' } />
                    </button>

                    {
                        isOpened &&
                        <div className="absolute top-full left-0 bg-white border rounded p-2 shadow grid grid-cols-4 gap-2 justify-items-center w-52">
                            {
                                isRectangle && Array.from(rectStyleMap).map(item => {
                                    const [ className ] = item;

                                    return (
                                        <button
                                            key={ `rect-${ className }` }
                                            onClick={ () => {
                                                changeStyle(className);
                                            }}
                                            className={ `w-8 h-4 ${ className } border border-2` }
                                            type="button"
                                        />
                                    )
                                })
                            }

                            {
                                isLine && Array.from(lineStyleMap).map(item => {

                                    const [ className ] = item;

                                    return (
                                        <button
                                            key={ `line-${ className }` }
                                            onClick={ () => {
                                                changeStyle(className);
                                            }}
                                            className={ `w-8 h-4 ${ className } border border-2` }
                                            type="button"
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
};

export default PredefinedStyleDropDown;