import { useFlowersStore } from '../../../data/flowers-store';
import WaterLog from './water-log';
import { useEffect } from 'react';
import { getWaterLogsData } from '../../../data/flowers-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';

const WaterLogsList = () => {

    const waterLogsData = useFlowersStore(store => store.waterLogsData);
    const setWaterLogsData = useFlowersStore(store => store.setWaterLogsData);

    useEffect(() => {
        (async () => {
            const response = await getWaterLogsData();

            if(!response) {
                showToast('Get water logs error.');
                return;
            }

            setWaterLogsData(response);
        })();
    }, [setWaterLogsData]);

    return (
        <div>
            {
                waterLogsData?.plants?.map(plant => {
                    return (
                        <WaterLog
                            key={ plant.plant_id }
                            plant={ plant }
                        />
                    )
                })
            }
        </div>
    )
};

export default WaterLogsList;