import { IFlowersResponse, IPlant, IPlantLocation, IPlantType, IWeather } from '../flowers-interfaces';
import { getWeather } from './weather-provider';

export enum LightIntensity {
    LowLight = 1,         // Minimal indirect light (e.g., shaded corners indoors)
    MediumLight = 2,      // Moderate indirect light (e.g., near a window with curtains)
    BrightLight = 3,      // Bright indirect light (e.g., near an unobstructed window)
    DirectSunLight = 4,   // Direct exposure to sunlight (e.g., outdoors or by a sunny window)
}

export enum PlantLifeCycle {
    Annual = 1,            // Однолетнее
    Biennial = 2,        // Двухлетнее
    Perennial = 3,      // Многолетнее
    Ephemeral = 4,      // Эфемерное
    Herbaceous = 5,    // Травянистое
}

export const formatFlowersResponse = (response: IFlowersResponse|null) : IFlowersResponse|null => {
    if(!response) return null;

    const plants = response.plants || [];
    const attachments = response.attachments || [];
    const locations = response.locations || [];
    const plantTypes = response.plantTypes || [];

    const plantIdMap = new Map<number, IPlant>(); // plant id ---> food
    for(const plant of plants) {
        plantIdMap.set(plant.plant_id, plant);
    }

    for(const attachment of attachments) {
        const plant = plantIdMap.get(attachment.plant_id);
        if(!plant) continue;

        plant.attachments = plant.attachments || [];
        plant.attachments.push(attachment);
    }

    const weather = getWeather();

    for(const plant of plants) {
        plant.location = locations.find(location => location.location_id === plant.plant_location_id) || null;
        plant.plantType = plantTypes.find(plantType => plantType.type_id === plant.plant_type_id) || null;
        plant.waterFrequency = getWaterFrequencyInDaysRounded(plant.plantType, plant.location, weather);
    }

    return response;
};

export const getWaterFrequencyInDays = (
    plantType: IPlantType,
    plantLocation: IPlantLocation,
    weather: IWeather,
) : number => {
    let frequency = plantType.base_watering_frequency || 7; // 7 days by default

    if (!plantLocation.is_indoor) {

        // Outdoor plants need more frequent watering
        frequency *= 0.8;
    }

    if (weather.temperature >= 30) {

        // Hot weather increases water needs (
        const temperatureFactor = 1 - ((weather.temperature - 30) / 50); // Decreases as temp rises
        frequency *= Math.max(temperatureFactor * plantType.temperature_weight, 0.5); // Ensure not too low
    }
    else {
        if (weather.temperature <= 15) {

            // Cold weather reduces water needs
            const temperatureFactor = 1 + ((15 - weather.temperature) / 50); // Increases as temp drops
            frequency *= Math.min(temperatureFactor * plantType.temperature_weight, 1.5); // Ensure not too high
        }
    }

    if (weather.humidity <= 50) {

        // Low humidity increases evaporation
        const humidityFactor = 1 - ((50 - weather.humidity) / 100); // Decreases as humidity drops
        frequency *= Math.max(humidityFactor * plantType.humidity_weight, 0.5); // Prevent too low
    }
    else {
        if (weather.humidity >= 80) {

            // High humidity reduces evaporation
            const humidityFactor = 1 + ((weather.humidity - 80) / 100); // Increases as humidity rises
            frequency *= Math.min(humidityFactor * plantType.humidity_weight, 1.5); // Prevent too high
        }
    }

    const seasonalAdjustments: Record<string, number> = {
        spring: 1.0,
        summer: 0.7,
        autumn: 1.2,
        winter: 1.5,
    };
    frequency *= plantType.seasonal_weight * (seasonalAdjustments[weather.seasonName] || 1.0);

    return frequency;
};

export const getWaterFrequencyInDaysRounded = (
    plantType: IPlantType,
    plantLocation: IPlantLocation,
    weather: IWeather,
) => {
    return Math.round(
        getWaterFrequencyInDays(plantType, plantLocation, weather)
    );
};

export const calculateNextWateringDate = (
    lastWatered: number,
    plantType: IPlantType,
    plantLocation: IPlantLocation,
    weather: IWeather,
) : number => {

    const frequency = getWaterFrequencyInDays(
        plantType,
        plantLocation,
        weather
    );

    /*// Calculate days since the last watering
    const today = new Date();
    const daysSinceLastWatering = Math.floor(
        (today.getTime() - lastWatered.getTime()) / (1000 * 60 * 60 * 24)
    );

    // Calculate days until next watering
    const daysUntilNextWatering = Math.max(
        Math.ceil(baseFrequency - daysSinceLastWatering),
        0
    );

    return daysUntilNextWatering;*/

    // Calculate next watering date
    const daysUntilNextWatering = Math.max(Math.ceil(frequency), 0);
    const lastWateredDate = new Date(lastWatered);

    const nextWateringDate = new Date(lastWatered);
    nextWateringDate.setDate(lastWateredDate.getDate() + daysUntilNextWatering);

    return nextWateringDate.getTime();
};
