import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramRectangleWithText } from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import RectangleSelectionCircle from './rectangle-selection-circle';
import {
    SELECTION_CIRCLE_COLOR,
    SELECTION_CIRCLE_HOVER_FILL, SELECTION_CIRCLE_HOVER_STROKE
} from '../../../../domain/todo-diagram-provider';

interface IRectangleSelectionProps {
    useStore: TodoDiagramUseStore;
    shape: DiagramRectangleWithText;
}

const RectangleSelection = ({ useStore, shape }: IRectangleSelectionProps) => {

    // Calculate positions for the 8 selection circles.
    const circles = shape.getSelectionCircles();
    const dragArrowCollidingCircleId = useStore(state => state.dragArrowCollidingCircleId);

    return (
        <g>
            {
                circles.map((circle, index) => (
                    <RectangleSelectionCircle
                        key={ index }
                        index={ circle.index }
                        cx={ circle.cx }
                        cy={ circle.cy }
                        lineEndX={ circle.lineEndX }
                        lineEndY={ circle.lineEndY }
                        cursor={ circle.cursor }
                        shape={ shape }
                        useStore={ useStore }
                        fill={ dragArrowCollidingCircleId === circle.index ? SELECTION_CIRCLE_HOVER_FILL : SELECTION_CIRCLE_COLOR }
                        stroke={ dragArrowCollidingCircleId === circle.index ? SELECTION_CIRCLE_HOVER_STROKE : SELECTION_CIRCLE_COLOR }
                    />
                ))
            }
        </g>
    )
};

export default RectangleSelection;