import { PlusIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { format, startOfDay, endOfDay } from 'date-fns';
import { showToast } from '../../../../../common/domain/toast-provider';
import { insertFoodLog } from '../../../data/food-data-service';
import { useFoodStore } from '../../../data/food-store';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';
import FoodDropDown from '../../foods/food-drop-down';

enum AddFoodLogMode {
    DEFAULT = 0,
    ADDING = 1,
}

const getNewFoodLogDateTime = (foodLogDate: number) : number => {
    if(!foodLogDate) return foodLogDate;

    const copy = new Date(foodLogDate);

    const current = new Date();
    const hours = current.getHours();

    let minutes = current.getMinutes();
    const temp = minutes % 10;
    minutes -= temp;

    copy.setHours(hours);
    copy.setMinutes(minutes);
    copy.setSeconds(0);
    copy.setMilliseconds(0);

    return copy.getTime();
};

const AddFoodLog = () => {

    const editorRef = useRef<MDXEditorMethods>(null);

    const foodLogDate = useFoodStore(store => store.foodLogDate);
    const setFoodLogData = useFoodStore(store => store.setFoodLogData);

    const [addFoodLogMode, setAddFoodLogMode] = useState(AddFoodLogMode.DEFAULT);
    const [isLoading, setLoading] = useState(false);

    const [date, setDate] = useState<number|null>(getNewFoodLogDateTime(foodLogDate));
    const [dateTouched, setDateTouched] = useState(false);
    const isDateValid = !dateTouched || date > 0;

    const [foodId, setFoodId] = useState(0);
    const [foodIdTouched, setFoodIdTouched] = useState(false);
    const isFoodIdValid = !foodIdTouched || foodId > 0;

    const [quantity, setQuantity] = useState(1);
    const [quantityTouched, setQuantityTouched] = useState(false);
    const isQuantityValid = !quantityTouched || quantity > 0;

    const startAddingFoodLog = () => {
        setAddFoodLogMode(AddFoodLogMode.ADDING);
    };

    const stopAddingFoodLog = () => {
        setAddFoodLogMode(AddFoodLogMode.DEFAULT);
    };

    const save = useCallback(async () => {
        if(foodId <= 0) {
            setFoodIdTouched(true);
            return;
        }

        if(date <= 0) {
            setDateTouched(true);
            return;
        }

        if(quantity <= 0) {
            setQuantityTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';
        const fromDate = startOfDay(foodLogDate).getTime();
        const toDate = endOfDay(foodLogDate).getTime();

        const response = await insertFoodLog(
            fromDate,
            toDate,

            foodId,
            date,
            quantity,
            desc.trim(),
        );

        setLoading(false);

        if(!response) {
            showToast('Insert food log error.');
            return;
        }

        stopAddingFoodLog();

        setFoodLogData(response);
    }, [date, foodId, foodLogDate, quantity, setFoodLogData]);

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = new Date(evt.target.value);
        setDate(_date.getTime());
    };

    const onQuantityChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number(evt.target.value) || 0;
        setQuantity(value);
        setQuantityTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {

        if(evt.code === 'Escape') {
            stopAddingFoodLog();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                const $box = (evt.target as HTMLElement).closest('[data-type="add-food-log"]');
                if(!$box) return;

                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                stopAddingFoodLog();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    useEffect(() => {
        setDate(getNewFoodLogDateTime(foodLogDate));
    }, [foodLogDate]);

    return (
        <div data-type="add-food-log">

            {
                addFoodLogMode === AddFoodLogMode.DEFAULT &&
                <button
                    className="flex items-center text-sm text-slate-400"
                    onClick={ startAddingFoodLog }
                    type="button">
                    <div className="mr-2"><PlusIcon /></div>
                    <div>Add Food Log</div>
                </button>
            }

            {
                addFoodLogMode === AddFoodLogMode.ADDING &&
               <div>

                   <label className="flex flex-col mb-4">
                       <div className="font-bold text-slate-400 mb-2">Food Type</div>
                       <FoodDropDown
                            foodId={ foodId }
                            setFoodId={ setFoodId }
                       />

                       {
                           !isFoodIdValid && <div className="text-red-700 text-xs mt-1">The food type is required.</div>
                       }
                   </label>

                   <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-4">

                       <label
                           className="font-bold text-slate-400"
                           htmlFor="food-log-date">Date</label>

                       <label
                           className="font-bold text-slate-400"
                           htmlFor="food-log-quantity">Quantity</label>

                       <div>
                           <input
                               id="food-log-date"
                               type="datetime-local"
                               className="w-full border rounded text-slate-800 outline-stone-200 px-4 py-2"
                               onKeyDown={ onKeyDown }
                               onChange={ onDateChange }
                               value={ date ? format(date, `yyyy-MM-dd'T'HH:mm`) : '' }
                           />

                           {
                               !isDateValid && <div className="text-red-700 text-xs mt-1">The date is required.</div>
                           }
                       </div>


                       <div className="flex flex-col">
                           <input
                               id="food-log-quantity"
                               value={ quantity }
                               onInput={ onQuantityChange }
                               onKeyDown={ onKeyDown }
                               className={ `border rounded px-4 py-2 ${ isQuantityValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                               type="number"
                           />

                           {
                               !isQuantityValid && <div className="text-red-700 text-xs mt-1">The quantity is required.</div>
                           }
                       </div>
                   </div>

                   <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
                   <div className="markdown-editor border rounded-lg mb-4">
                       <MDXEditor
                           ref={ editorRef }
                           markdown={ '' }
                           plugins={ getCompactMarkdownEditorPlugins() }
                       />
                   </div>

                   <div className="flex items-center justify-end text-sm mt-4">
                       <button
                           className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                           onClick={ stopAddingFoodLog }
                           type="button">Cancel</button>

                       {
                           isLoading &&
                           <PreloaderIcon size={ 24 } color={ '#717985' } />
                       }

                       {
                           !isLoading &&
                           <button
                               className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                               onClick={ save }
                               type="button">Save</button>
                       }
                   </div>
               </div>
            }
        </div>
    )
};

export default AddFoodLog;