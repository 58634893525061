import { IPlantWaterCalendarDay } from '../../flowers-interfaces';
import { addWaterLog, deleteWaterLog } from '../../data/flowers-data-service';
import { startOfDay } from 'date-fns';
import { showToast } from '../../../../common/domain/toast-provider';
import { useFlowersStore } from '../../data/flowers-store';
import { createPlantWaterCalendar } from '../../domain/flowers-calendar-provider';

interface IDayViewProps {
    day: IPlantWaterCalendarDay;
    plant_id: number
}

const DayView = ({ day, plant_id }: IDayViewProps) => {

    const year = useFlowersStore(store => store.year);
    const setCalendarData = useFlowersStore(store => store.setCalendarData);

    const removeWaterLogHandler = async () => {
        const date_time = startOfDay(day.dayStartDate);

        const response = await deleteWaterLog(plant_id, date_time.getTime());

        if(!response?.isValid) {
            showToast('Delete water log error.');
            return;
        }

        setCalendarData(createPlantWaterCalendar(
            plant_id,
            year,
            response.logs || []
        ));
    };

    const addWaterLogHandler = async () => {
        const date_time = startOfDay(day.dayStartDate);
        const response = await addWaterLog(plant_id, date_time.getTime());

        if(!response?.isValid) {
            showToast('Add water log error.');
            return;
        }

        setCalendarData(createPlantWaterCalendar(
            plant_id,
            year,
            response.logs || []
        ));
    };

    return (
        <div
             className={ `bg-slate-50 p-1 ${ day.isTodayDay ? 'border border-slate-500' : '' }` }>
            <div className="mb-1 text-right text-xs">{ day.dayName }</div>
            <div className={ `text-center text-sm text-black` }>

                {
                    day.hasWatering &&
                    <button
                        title="Remove Water Log"
                        onClick={ removeWaterLogHandler }
                        type="button">
                        ✅
                    </button>
                }

                {
                    !day.hasWatering &&
                    <button
                        title="Add Water Log"
                        className="w-full"
                        onClick={ addWaterLogHandler }
                        type="button">
                        &nbsp;
                    </button>
                }
            </div>
        </div>

    );
}

export default DayView;