import { IWeather } from '../flowers-interfaces';

/**
 * Average Weather Data for Israel.
 */
export const getWeather = (): IWeather => {

    const today = new Date();

    // JavaScript months are 0-based (0 = January, 11 = December)
    const month = today.getMonth();

    if (month >= 2 && month <= 4) {
        // Spring: March to May ------
        return {
            temperature: 20, // Celsius
            humidity: 50, // %
            sunlightHours: 8, // per day
            seasonName: 'spring',
        };
    }

    if (month >= 5 && month <= 7) {
        // Summer: June to August ------
        return {
            temperature: 30, // Celsius
            humidity: 40, // %
            sunlightHours: 11, // per day
            seasonName: 'summer',
        };
    }

    if (month >= 8 && month <= 10) {
        // Autumn: September to November ------
        return {
            temperature: 25, // Celsius
            humidity: 55, // %
            sunlightHours: 9, // per day
            seasonName: 'autumn',
        };
    }

    // Winter: December to February ------
    return {
        temperature: 15, // Celsius
        humidity: 65, // %
        sunlightHours: 6, // per day
        seasonName: 'winter',
    };
};
