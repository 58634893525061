import { DownloadIcon } from '../../../../common/ui/icons';

const ExportFoodDataHome = () => {

    return (
        <div className="xl:max-w-[800px] max-w-full mx-auto py-10">

            <h1 className="flex items-center text-xl xl:text-3xl mb-6 border-b pb-1">
                <DownloadIcon color={ '#000' } size={ 30 } />
                <span className="ml-4">Food Export</span>
            </h1>

            <div>
                <a
                    href="/api/food/food-export/export-to-json"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center justify-center bg-sky-100 border border-sky-200 px-10 py-2 text-sky-900 rounded text-center">Export to JSON</a>
            </div>
        </div>
    )
};

export default ExportFoodDataHome;