import { DragEvent } from 'react';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { TODO_DIAGRAM_TOOLBAR_DRAG_CLASS, updateHistory } from '../../../domain/todo-diagram-provider';
import { TodoDiagramUseStore } from '../../../data/store';
import { v4 as uuidv4 } from 'uuid';
import { DiagramArrow } from '../../../domain/diagram-elements/elements/diagram-arrow';
import { DiagramLine } from '../../../domain/diagram-elements/elements/diagram-line';

interface IArrowBtnProps {
    useStore: TodoDiagramUseStore;
}

const ArrowBtn = ({ useStore }: IArrowBtnProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const onClick = () => {
        const copy = data.clone();
        copy.children = [...(copy.children || [])];

        const { cx, cy } = data?.getSvgCenter() || { cx: 0, cy: 0 };

        const shape = new DiagramArrow(
            uuidv4(),
            cx - DiagramLine.defaultSize/2,
            cy + DiagramLine.defaultSize/2,
            cx + DiagramLine.defaultSize/2,
            cy - DiagramLine.defaultSize/2,
            DiagramArrow.arrowHeadSize,
            '#000',
            2,
        );
        copy.children.push(shape);

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
        setSelectedElementId(shape.id);
    };

    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', DiagramElementType.Arrow.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return (
        <button
            className="mr-4"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            onClick={ onClick }
            title="Rectangle"
            type="button">

            <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(17.000000, 17.000000) rotate(45.000000) translate(-17.000000, -17.000000) translate(5.000000, 5.000000)">
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <line x1="12" y1="5" x2="12" y2="19" id="Path" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="16" y1="9" x2="12" y2="5" id="Path" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="8" y1="9" x2="12" y2="5" id="Path" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>
                </g>
            </svg>
        </button>
    )
};

export default ArrowBtn;