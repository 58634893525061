import { DiagramElementType } from './diagram-elements/diagram-element-type-enum';
import { DiagramSvg } from './diagram-elements/diagram-svg';
import {
    DiagramRectangleWithText,
    IDiagramRectangleSelectionCircle
} from './diagram-elements/elements/diagram-rectangle-with-text';
import { v4 as uuidv4 } from 'uuid';
import { DiagramLine } from './diagram-elements/elements/diagram-line';
import { DiagramArrow } from './diagram-elements/elements/diagram-arrow';
import { DiagramElement } from './diagram-elements/diagram-element';
import { DiagramText } from './diagram-elements/elements/diagram-text';

export const TODO_DIAGRAM_TOOLBAR_DRAG_CLASS = 'todo-diagram-toolbar-dragging';
export const TODO_DIAGRAM_DRAG_OVER_CLASS = 'todo-diagram-drag-over';

export const SELECTION_CIRCLE_RADIUS = 5;
export const SELECTION_CIRCLE_COLOR = '#444';
export const SELECTION_CIRCLE_HOVER_FILL = '#00dd80';
export const SELECTION_CIRCLE_HOVER_STROKE = '#0f5517';
export const SVG_SELECTION_SIZE = 10;
export const SVG_SELECTION_COLOR = 'rgb(100 116 139)'

const ARROW_OFFSET = 1;
export const MAX_DATA_STACK = 10;

export const mapToSvgCoordinates = ($svg: SVGSVGElement, clientX: number, clientY: number) => {

    // An SVGPoint is an object provided by the SVG DOM API
    // to represent a single point in an SVG coordinate system.
    // It allows you to work with points in terms of an
    // SVG's internal coordinate system,
    // which may differ from the screen or viewport coordinates
    // (like clientX and clientY).
    const point = $svg.createSVGPoint();
    point.x = clientX;
    point.y = clientY;

    // CTM = Current Transformation Matrix of an SVG element.
    // This matrix represents how the element's local coordinate system
    // maps to the screen coordinate system (the browser's viewport).
    // It calculates how an SVG element, possibly transformed
    // (e.g., translated, rotated, scaled), aligns with the screen.
    // a (scale X)
    // b (skew Y)
    // c (skew X)
    // d (scale Y)
    // e (translate X)
    // f (translate Y)
    const ctm = $svg.getScreenCTM();

    // Transform to SVG coordinate system.
    // If you invert this matrix (using inverse()),
    // it tells you how to map points in the screen coordinate system
    // back into the SVG's coordinate system.
    // The matrixTransform() method takes a point (in screen coordinates)
    // and applies a matrix transformation to map it into a new coordinate space.
    // In this case, we're using the inverted matrix (ctm?.inverse())
    // to map a point from screen coordinates into SVG coordinates.
    // Without inverse(), the transformation would move points further
    // into the screen's coordinate space, which isn’t what we need.
    return point.matrixTransform(ctm?.inverse());
};

export const addElementByType = (
    elementType: DiagramElementType,
    data: DiagramSvg,
    $svg: SVGSVGElement,
    clientX: number,
    clientY: number
) => {
    let shape: DiagramElement|null = null;
    const copy = data.clone();
    copy.children = [...(copy.children || [])];

    const transformedPoint = mapToSvgCoordinates($svg, clientX, clientY);

    switch (elementType) {
        case DiagramElementType.RectangleWithText: {
            shape = new DiagramRectangleWithText(
                uuidv4(),
                DiagramRectangleWithText.defaultText,
                transformedPoint.x,
                transformedPoint.y,
                DiagramRectangleWithText.defaultWidth,
                DiagramRectangleWithText.defaultHeight,
                DiagramRectangleWithText.defaultFill,
                DiagramRectangleWithText.defaultStroke,
                DiagramRectangleWithText.defaultStrokeWidth,
                0,
                0,
                DiagramRectangleWithText.defaultTextColor,
                DiagramRectangleWithText.defaultTextFontSize,
                false,
                false,
                false,
            );
            copy.children.push(shape);
            break;
        }

        case DiagramElementType.RoundedRectangleWithText: {
            shape = new DiagramRectangleWithText(
                uuidv4(),
                DiagramRectangleWithText.defaultText,
                transformedPoint.x,
                transformedPoint.y,
                DiagramRectangleWithText.defaultWidth,
                DiagramRectangleWithText.defaultHeight,
                DiagramRectangleWithText.defaultFill,
                DiagramRectangleWithText.defaultStroke,
                DiagramRectangleWithText.defaultStrokeWidth,
                DiagramRectangleWithText.defaultRadius,
                DiagramRectangleWithText.defaultRadius,
                DiagramRectangleWithText.defaultTextColor,
                DiagramRectangleWithText.defaultTextFontSize,
                false,
                false,
                false,
            );
            copy.children.push(shape);
            break;
        }

        case DiagramElementType.Text: {
            shape = new DiagramText(
                uuidv4(),
                DiagramRectangleWithText.defaultText,
                transformedPoint.x,
                transformedPoint.y,
                DiagramRectangleWithText.defaultWidth,
                DiagramRectangleWithText.defaultHeight,
                DiagramRectangleWithText.defaultTextColor,
            );
            copy.children.push(shape);
            break;
        }

        case DiagramElementType.Line: {
            shape = new DiagramLine(
                uuidv4(),
                transformedPoint.x - DiagramLine.defaultSize,
                transformedPoint.y + DiagramLine.defaultSize,
                transformedPoint.x,
                transformedPoint.y,
                '#000',
                2,
            );
            copy.children.push(shape);
            break;
        }

        case DiagramElementType.Arrow: {
            shape = new DiagramArrow(
                uuidv4(),
                transformedPoint.x - DiagramArrow.defaultSize,
                transformedPoint.y + DiagramArrow.defaultSize,
                transformedPoint.x,
                transformedPoint.y,
                DiagramArrow.arrowHeadSize,
                '#000',
                2,
            );
            copy.children.push(shape);
            break;
        }
    }

    return { copy, shape };
};

export const tryGetSvgElement = (target: EventTarget) : SVGSVGElement|null => {
    if(!target) return null;

    if (target instanceof SVGSVGElement) {
        return target as SVGSVGElement;
    }

    if(target instanceof SVGElement) {
        return target?.closest('svg') as SVGSVGElement;
    }

    return null;
};

export const resizeSvg = (
    data: DiagramSvg,
    copy: DiagramSvg,
    newX: number,
    newY: number,
    shape: DiagramRectangleWithText
) : DiagramSvg => {
    if (newX < data.x) {
        const diff = Math.abs(data.x - newX);
        copy.x = data.x - diff;
        copy.width += diff;
    }

    if (newY < data.y) {
        const diff = Math.abs(data.y - newY);
        copy.y = data.y - diff;
        copy.height += diff;
    }

    if (newX + shape.width > data.x + data.width) {
        const diff = (newX + shape.width) - (data.x + data.width);
        copy.width += diff * 2;
    }

    if (newY + shape.height > data.y + data.height) {
        const diff = (newY + shape.height) - (data.y + data.height);
        copy.height += diff;
    }

    return copy;
};

const isCircleRectangleColliding = (cx: number, cy: number, r: number, rx: number, ry: number, rw: number, rh: number) : boolean => {

    // Find the closest point on the rectangle to the circle's center.
    const closestX = Math.max(rx, Math.min(cx, rx + rw));
    const closestY = Math.max(ry, Math.min(cy, ry + rh));

    // Calculate the distance between the circle's center and this closest point.
    const distanceX = cx - closestX;
    const distanceY = cy - closestY;

    // Check if the distance is less than or equal to the circle's radius.
    return (distanceX ** 2 + distanceY ** 2) <= (r ** 2);
};

/**
 * Circles collision detection.
 * This algorithm works by taking the center points of the two circles
 * and ensuring the distance between the center points
 * are less than the two radii added together.
 * Returns true if collision is detected.
 */
export const areCirclesCollide = (cx1: number, cy1: number, r1: number, cx2: number, cy2: number, r2: number) => {
    const dx = Math.abs(cx1 - cx2);
    const dy = Math.abs(cy1 - cy2);
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance <= r1 + r2;
};

export interface ICollidingRectangleResult {
    collidingRect: DiagramRectangleWithText|null;
    collidingSelectionCircle: IDiagramRectangleSelectionCircle|null;
}

export const getCollidingRectangle = (
    cx: number,
    cy: number,
    rectangles: DiagramRectangleWithText[]
) : ICollidingRectangleResult => {

    for(const rect of rectangles) {
        if(isCircleRectangleColliding(
            cx,
            cy,
            SELECTION_CIRCLE_RADIUS,
            rect.x,
            rect.y,
            rect.width,
            rect.height
        )) {

            // Find out if the selection circle of the line
            // collides with selection circle of the rectangle.
            const rectCircles = rect.getSelectionCircles();
            let selectionCircle: IDiagramRectangleSelectionCircle|null = null;

            for(const rectSelectionCircle of rectCircles) {
                if(areCirclesCollide(
                    rectSelectionCircle.cx,
                    rectSelectionCircle.cy,
                    SELECTION_CIRCLE_RADIUS,
                    cx,
                    cy,
                    SELECTION_CIRCLE_RADIUS,
                )) {
                    selectionCircle = rectSelectionCircle;
                    break;
                }
            }

            return {
                collidingRect: rect,
                collidingSelectionCircle: selectionCircle,
            };
        }
    }

    return {
        collidingRect: null,
        collidingSelectionCircle: null,
    };
};

export interface IConnectionPoint {
    lineOrArrow: DiagramLine|DiagramArrow;
    isStart: boolean;
    xDiff: number;
    yDiff: number;
}

export const findAttachedLines = (data: DiagramSvg, rectangle: DiagramRectangleWithText) : IConnectionPoint[] => {
    const linesOrArrows = (data.children?.filter(item => item.type === DiagramElementType.Line ||
                                                                        item.type === DiagramElementType.Arrow) || []) as (DiagramLine|DiagramArrow)[];
    const result: IConnectionPoint[] = [];

    const rectCircles = rectangle.getSelectionCircles();

    for(const rectCircle of rectCircles) {
        for(const line of linesOrArrows) {

            const isCollideWithLineStart = areCirclesCollide(

                // Circle around [x1, y1].
                line.x1,
                line.y1,
                SELECTION_CIRCLE_RADIUS,
                rectCircle.cx,
                rectCircle.cy,
                SELECTION_CIRCLE_RADIUS
            );

            if(isCollideWithLineStart) {
                result.push({
                    lineOrArrow: line,
                    isStart: true,
                    xDiff: line.x1 - rectangle.x,
                    yDiff: line.y1 - rectangle.y,
                });
                continue;
            }

            const isCollideWithLineEnd = areCirclesCollide(
                line.x2,
                line.y2,
                SELECTION_CIRCLE_RADIUS,
                rectCircle.cx,
                rectCircle.cy,
                SELECTION_CIRCLE_RADIUS
            );

            if(isCollideWithLineEnd) {
                result.push({
                    lineOrArrow: line,
                    isStart: false,
                    xDiff: line.x2 - rectangle.x,
                    yDiff: line.y2 - rectangle.y,
                });
            }
        }
    }

    return result;
};

export const updateHistory = (dataStack: string[], data: DiagramSvg) : string[] => {
    const stack = [...dataStack];

    if(stack?.length >= MAX_DATA_STACK) {
        stack.shift();
    }

    stack.push(JSON.stringify(data));

    return stack;
};

const DIRECTION_MAP: Record<string, { x: number; y: number }> = {
    ArrowLeft: { x: -ARROW_OFFSET, y: 0 },
    ArrowRight: { x: ARROW_OFFSET, y: 0 },
    ArrowUp: { x: 0, y: -ARROW_OFFSET },
    ArrowDown: { x: 0, y: ARROW_OFFSET },
};

export const handleArrowKeys = (data: DiagramSvg, keyCode: string, selectedElement: DiagramElement) : DiagramElement => {
    if(!selectedElement || !keyCode) return selectedElement;

    const copy = selectedElement.clone();
    const direction = DIRECTION_MAP[keyCode];

    if(selectedElement.type === DiagramElementType.RectangleWithText ||
        selectedElement.type === DiagramElementType.RoundedRectangleWithText ||
        selectedElement.type === DiagramElementType.Text) {

        const rect = copy as DiagramRectangleWithText;
        rect.x += direction.x;
        rect.y += direction.y;

        const connectedLines = findAttachedLines(data, rect);

        for(const item of connectedLines) {

            if(item.isStart) {
                item.lineOrArrow.x1 += direction.x;
                item.lineOrArrow.y1 += direction.y;
            }
            else{
                item.lineOrArrow.x2 += direction.x;
                item.lineOrArrow.y2 += direction.y;
            }
        }
    }

    if(selectedElement.type === DiagramElementType.Line ||
        selectedElement.type === DiagramElementType.Arrow) {
        const line = copy as DiagramLine;
        line.x1 += direction.x;
        line.x2 += direction.x;
        line.y1 += direction.y;
        line.y2 += direction.y;
    }

    return copy;
};

export const getGuideLinesForRect = (selectedRect: DiagramRectangleWithText, data: DiagramSvg) : DiagramLine[] => {

    if(!selectedRect) return [];

    const rectangles = (data?.children?.filter(item => item.id !== selectedRect.id &&
                                                                        (item.type === DiagramElementType.RectangleWithText ||
                                                                         item.type === DiagramElementType.RoundedRectangleWithText)) || []) as DiagramRectangleWithText[];

    if(!rectangles.length) return [];

    const guideLines: DiagramLine[] = [];

    const stroke = '#5daed2';

    for(const rect of rectangles) {

        const {
            leftX: selectedLeftX,
            rightX: selectedRightX,

            topY: selectedTopY,
            bottomY: selectedBottomY,

            centerX: selectedCenterX,
            centerY: selectedCenterY,
        } = selectedRect.getPoints();

        const {
            leftX: rectLeftX,
            rightX: rectRightX,

            topY: rectTopY,
            bottomY: rectBottomY,

            centerX: rectCenterX,
            centerY: rectCenterY,
        } = rect.getPoints();

        const isSelectedUpper = selectedRect.y < rect.y;
        const isSelectedLeft = selectedRect.x < rect.x;

        // console.log('selectedLeftX', selectedLeftX, 'rectTopLeftX', rectTopLeftX)

        const guideLineData = [
            // Find all rectangles that x-coordinate of the top left corner matches.
            {
                match: selectedLeftX === rectLeftX,
                x1: rect.x,
                x2: rect.x,
                y1: isSelectedUpper ? selectedRect.y : rect.y,
                y2: isSelectedUpper ? rectBottomY : selectedBottomY,
            },
            // Find all rectangles that x-coordinate of the top right corner matches.
            {
                match: selectedRightX === rectRightX,
                x1: selectedRightX,
                x2: selectedRightX,
                y1: isSelectedUpper ? selectedRect.y : rect.y,
                y2: isSelectedUpper ? rectBottomY : selectedBottomY,
            },
            // Find all rectangles that x-coordinate of the center matches.
            {
                match: selectedCenterX === rectCenterX,
                x1: selectedCenterX,
                x2: selectedCenterX,
                y1: isSelectedUpper ? selectedCenterY : rectCenterY,
                y2: isSelectedUpper ? rectCenterY : selectedCenterY,
            },
            // Find all rectangles that y-coordinate of the top left corner matches.
            {
                match: selectedTopY === rectTopY,
                x1: isSelectedLeft ? selectedRect.x : rect.x,
                x2: isSelectedLeft ? rectLeftX : selectedLeftX,
                y1: rect.y,
                y2: rect.y,
            },
            // Find all rectangles that y-coordinate of the bottom left corner matches.
            {
                match: selectedBottomY === rectBottomY,
                x1: isSelectedLeft ? selectedRect.x : rect.x,
                x2: isSelectedLeft ? rectLeftX : selectedLeftX,
                y1: selectedBottomY,
                y2: selectedBottomY,
            },
            // Find all rectangles that y-coordinate of the center matches.
            {
                match: selectedCenterY === rectCenterY,
                x1: isSelectedLeft ? selectedCenterX : rectCenterX,
                x2: isSelectedLeft ? rectCenterX : selectedCenterX,
                y1: selectedCenterY,
                y2: selectedCenterY,
            },
        ];

        for(const { match, x1, y1, x2, y2 } of guideLineData) {
            if(!match) continue;

            guideLines.push(new DiagramLine(
                `guide-${ uuidv4() }`,
                x1,
                y1,
                x2,
                y2,
                stroke,
            ));
        }
    }

    return guideLines;
};
