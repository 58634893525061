import { IPlantType } from '../../flowers-interfaces';

interface IPlantTypeColorIndicator {
    plantType: IPlantType;
}

const PlantTypeColorIndicator = ({ plantType }: IPlantTypeColorIndicator) => {
    return (
        <div
            title={ plantType?.type_name }
            className="mr-4"
            style={ {
                width: '10px',
                height: '10px',
                backgroundColor: plantType?.type_color ?? '#efefef',
            } }
        />
    )
};

export default PlantTypeColorIndicator;