import { useCallback } from 'react';
import { insertTodo } from '../../data/planner-data-service';
import { openTodo } from '../../domain/planner-storage';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import { TodoType } from '../../planner-interfaces';
import { usePlannerStore } from '../../data/planner-store';
import CommonTodoAdd from '../../../../common/ui/todo/common-todo-add';

interface INewTodoProps {
    project_id: number;
    section_id?: number;
    parent_todo_id?: number|string;
    parent_todo_title?: string;
    order: number;
    close: () => void;
}

const NewTodo = ({
                     order, close, project_id, section_id,
                     parent_todo_id, parent_todo_title,
}: INewTodoProps) => {

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const save = useCallback(async (
        project_id: number,
        section_id: number,

        title: string,
        desc: string,

        address: string,
        order: number,

        date: number|null,
        hasTime: number,

        type: TodoType,
        isTodoActive: number,
        shouldShowInOverview: number,

        tags: string,
        idealFinalResult: string,

        isHabitTrackerActive?: boolean,
        habitInterval?: number,
        habitQtyPerInterval?: number,
        habit_week_days?: string,
    ) => {

        const response = await insertTodo(
            project_id,
            section_id,
            parent_todo_id,
            title.trim(),
            desc.trim(),
            address,
            order,
            date,
            hasTime ? 1 : 0,
            type,
            isTodoActive ? 1 : 0,
            shouldShowInOverview ? 1 : 0,
            tags,
            idealFinalResult,
            isHabitTrackerActive,
            habitInterval,
            habitQtyPerInterval,
            habit_week_days,
        );

        if(!response) {
            showToast('Insert task error.');
            return;
        }

        close();

        // Open parent _todo if it exists and closed.
        if(parent_todo_id) {
            openTodo(parent_todo_id);
        }

        setCombinedProject(combineProjectData(response));
    }, [
        close,
        parent_todo_id,
        setCombinedProject,
    ]);

    return (
        <CommonTodoAdd
            project_id={ project_id }
            section_id={ section_id }
            parent_todo_id={ parent_todo_id }
            parent_todo_title={ parent_todo_title }
            order={ order }
            close={ close }
            allTags={ combinedProject?.tags }
            saveCallback={ save }
        />
    )
};

export default NewTodo;