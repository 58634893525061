import ProjectTitle from './project-title';
import ProjectDescription from './description/project-description';
import Section from '../section/section';
import { IProject } from '../../planner-interfaces';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTextFromMarkdown } from '../../../../common/markdown/markdown-provider';
import { canAddSections, isSpecialProjectType, shouldHideTitles } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';

interface IPlannerHomeProjectProps {
    project: IProject;
}

const ProjectView = ({ project }: IPlannerHomeProjectProps) => {

    const { project_id } = useParams();
    const listType = usePlannerStore(state => state.listType);
    const selectedFilterTags = usePlannerStore(state => state.selectedFilterTags);

    const _projectHideTitles = usePlannerStore(store => store.projectHideTitles);
    const projectHideTitles = shouldHideTitles(_projectHideTitles, listType, selectedFilterTags);
    const isSpecial = isSpecialProjectType(listType);

    /**
     * Project is opened conditions:
     * ------------------------------
     * - Project is always opened on non-special pages.
     * - On special pages `project.isOpened` should be true or
     *   projectHideTitles is true.
     */
    const isOpened = !isSpecial || (isSpecial && (project.isOpened || projectHideTitles));
    const isTitleHidden = isSpecial && projectHideTitles;
    const ifCanAddSections = canAddSections(listType);
    const sections = project.sections || [];

    useEffect(() => {
        if(project.project_id.toString() !== project_id) return;
        document.title = `${ getTextFromMarkdown(project.project_title) } | Productivity`;
    }, [project.project_title, project.project_id, project_id]);

    return (
        <div className={ `xl:w-[800px] max-w-full mx-auto ${ ifCanAddSections ? '' : 'mb-4' }` }>
            {
                !isTitleHidden &&
                <div className="mb-3 w-full flex flex-col">
                    <ProjectTitle
                        project={ project }
                    />

                    {
                        !isSpecial &&
                        <ProjectDescription
                            project={ project }
                        />
                    }
                </div>
            }

            {
                isOpened && sections.map(
                    (section, i) =>
                        <Section
                            key={ `section-${ section.section.section_id }`}
                            combinedSection={ section }
                            project={ project }
                            isFirst={ i === 0 }
                            showEmptyTitle={
                                isSpecial &&
                                sections.length > 1 &&
                                section.todos.length > 0
                            }
                        />
                )
            }
        </div>
    )
};

export default ProjectView;