import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IPlantType } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { deletePlantType } from '../../../data/flowers-data-service';

interface IDeletePlantType {
    plantType: IPlantType;
}

const DeletePlantType = ({ plantType }: IDeletePlantType) => {

    const [isLoading, setLoading] = useState(false);

    const setPlantTypesData = useFlowersStore(store => store.setPlantTypesData);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete the plant type "${ plantType.type_name }"?`)) return;

        setLoading(true);
        const response = await deletePlantType(plantType.type_id);
        setLoading(false);

        if(!response) {
            showToast('Delete plant type error.');
            return;
        }

        setPlantTypesData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Plant Type"
                    className="plant-type__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeletePlantType;