import { create } from 'zustand';
import { IFood, IFoodCategoryResponse, IFoodLog, IFoodLogResponse, IFoodResponse } from '../food-interfaces';
import { FoodCategoriesTab } from '../ui/categories/tabs-navigation';
import { FoodItemsTab } from '../ui/foods/tabs-navigation';
import { getFoodCategoryFromStorage } from '../domain/food-storage';

interface FoodState {

    // -------- Categories -----------------------

    foodCategoriesData: IFoodCategoryResponse|null;
    setFoodCategoriesData: (_foodBusinessData: IFoodCategoryResponse|null) => void;

    foodCategoriesTab: FoodCategoriesTab;
    setFoodCategoriesTab: (_categoriesTab: FoodCategoriesTab) => void;

    // -------- Food Items -----------------------

    foodItemsData: IFoodResponse|null;
    setFoodItemsData: (_foodItemsData: IFoodResponse|null) => void;

    foodItemsTab: FoodItemsTab;
    setFoodItemsTab: (_foodItemsTab: FoodItemsTab) => void;

    foodItemsPageNumber: number;
    setFoodItemsPageNumber: (_foodItemsPageNumber: number) => void;

    foodItemsSelectedCategoryID: number;
    setFoodItemsSelectedCategoryID: (_foodItemsSelectedCategoryID: number) => void;

    foodItemsEditPopup: IFood|null;
    setFoodItemsEditPopup: (_foodItemsEditPopup: IFood|null) => void;

    foodItemsSearchTerm: string;
    setFoodItemsSearchTerm: (_foodItemsSearchTerm: string) => void;

    // ------- Food Log ---------------------

    foodLogDate: number,
    setFoodLogDate: (_foodLogDate: number) => void;

    foodLogData: IFoodLogResponse|null;
    setFoodLogData: (_foodLogData: IFoodLogResponse|null) => void;

    foodLogEditPopup: IFoodLog|null;
    setFoodLogEditPopup: (_foodLogEditPopup: IFoodLog|null) => void;

    // ------- Food Calendar -----------------

    currentCalendarYear: number;
    setCurrentCalendarYear: (_currentCalendarYear: number) => void;
}

export const useFoodStore = create<FoodState>()((set) => ({

    // -------- Categories -----------------------

    foodCategoriesData: null,
    setFoodCategoriesData: (_foodCategoriesData: IFoodCategoryResponse|null) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodCategoriesData: _foodCategoriesData,
            };
        });
    },

    foodCategoriesTab: FoodCategoriesTab.Default,
    setFoodCategoriesTab: (_foodCategoriesTab: FoodCategoriesTab) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodCategoriesTab: _foodCategoriesTab,
            };
        });
    },

    // -------- Food Items -----------------------

    foodItemsData: null,
    setFoodItemsData: (_foodItemsData: IFoodResponse|null) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsData: _foodItemsData,
            };
        });
    },

    foodItemsTab: FoodItemsTab.Default,
    setFoodItemsTab: (_foodItemsTab: FoodItemsTab) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsTab: _foodItemsTab,
            };
        });
    },

    foodItemsSearchTerm: '',
    setFoodItemsSearchTerm: (_foodItemsSearchTerm: string)  => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsSearchTerm: _foodItemsSearchTerm,
            };
        });
    },

    foodItemsPageNumber: 0,
    setFoodItemsPageNumber: (_foodItemsPageNumber: number) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsPageNumber: _foodItemsPageNumber,
            };
        });
    },

    foodItemsSelectedCategoryID: getFoodCategoryFromStorage(),
    setFoodItemsSelectedCategoryID: (_foodItemsSelectedCategoryID: number) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsSelectedCategoryID: _foodItemsSelectedCategoryID,
            };
        });
    },

    foodItemsEditPopup: null,
    setFoodItemsEditPopup: (_foodItemsEditPopup: IFood|null) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodItemsEditPopup: _foodItemsEditPopup,
            };
        });
    },

    // ------- Food Log ---------------------

    foodLogDate: (new Date()).getTime(),
    setFoodLogDate: (_foodLogDate: number) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodLogDate: _foodLogDate,
            };
        });
    },

    foodLogData: null,
    setFoodLogData: (_foodLogData: IFoodLogResponse|null) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodLogData: _foodLogData,
            };
        });
    },

    foodLogEditPopup: null,
    setFoodLogEditPopup: (_foodLogEditPopup: IFoodLog|null) => {
        return set((state: FoodState) => {
            return {
                ...state,
                foodLogEditPopup: _foodLogEditPopup,
            };
        });
    },

    // ------- Food Calendar -----------------

    currentCalendarYear: (new Date()).getFullYear(),
    setCurrentCalendarYear: (_currentCalendarYear: number) => {
        return set((state: FoodState) => {
            return {
                ...state,
                currentCalendarYear: _currentCalendarYear,
            };
        });
    },

}))