import { SunIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';

const TodayButton = () => {

    const setCurrentCalendarYear = useFoodStore(store => store.setCurrentCalendarYear);

    const jumpToday = () => {
        setCurrentCalendarYear((new Date()).getFullYear());
    };

    return (
        <button
            title="Current Year"
            onClick={ jumpToday }
            className="flex items-center"
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mr-1"
            />
            Today
        </button>
    )
};

export default TodayButton;