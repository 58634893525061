import { IPlantLocation } from '../../flowers-interfaces';
import LightIntensityIcon from '../light-intensity/light-intensity-icon';

interface IPlantLocationText {
    location: IPlantLocation|null;
}

const PlantLocationText = ({ location } : IPlantLocationText) => {
    return (
        <>
            {
                !!location &&
                <div className="text-sm flex items-center mr-4">

                    <LightIntensityIcon
                        lightIntensity={ location.light_intensity }
                    />

                    { location.location_name }
                </div>
            }
        </>
    )
};

export default PlantLocationText;