import { IPlant } from '../../../flowers-interfaces';
import { CalendarIcon } from '../../../../../common/ui/icons';

interface IOpenPlantCalendarBtn {
    plant: IPlant;
}

const OpenPlantCalendarBtn = ({ plant }: IOpenPlantCalendarBtn) => {

    return (
        <>
            <a
                title="Open Plant Calendar"
                className="flex bg-indigo-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                href={ `/flowers/plant-calendar/${ plant.plant_id }` }>
                <CalendarIcon size={ 20 } color={ `#41538e` } />
            </a>
        </>
    )
};

export default OpenPlantCalendarBtn;