import { DragEvent, Fragment } from 'react';
import { addElementByType, TODO_DIAGRAM_DRAG_OVER_CLASS, updateHistory } from '../../domain/todo-diagram-provider';
import { TodoDiagramUseStore } from '../../data/store';
import { DiagramElementType } from '../../domain/diagram-elements/diagram-element-type-enum';
import SvgRectangleWithText from '../svg-elements/rectangle-with-text/rectangle-with-text';
import { DiagramRectangleWithText } from '../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { showToast } from '../../../../common/domain/toast-provider';
import { DiagramArrow } from '../../domain/diagram-elements/elements/diagram-arrow';
import SvgArrow from '../svg-elements/lines-and-arrows/arrow';
import SvgSelection from './svg-selection';

interface ITodoDiagramSvgProps {
    useStore: TodoDiagramUseStore;
}

const photoShopLikeBgImage = 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=")';

const TodoDiagramSvg = ({ useStore }: ITodoDiagramSvgProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const targetOnDragOver = (evt: DragEvent<SVGSVGElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<SVGSVGElement>) => {
        evt.currentTarget.classList.add(TODO_DIAGRAM_DRAG_OVER_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<SVGSVGElement>) => {
        evt.currentTarget.classList.remove(TODO_DIAGRAM_DRAG_OVER_CLASS);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<SVGSVGElement>) => {
        evt.preventDefault();

        const transferredData = evt.dataTransfer.getData('text');

        if(transferredData === null ||
            transferredData === undefined ||
            transferredData.trim() === '') {
            showToast('Drop error.');
            return;
        }

        const elementType = Number(transferredData) as DiagramElementType;

        if(isNaN(Number(elementType))) {
            showToast('Drop error.');
            return;
        }

        // The currentTarget is the element to which the event handler has been attached.
        const $svg = evt.currentTarget as SVGSVGElement;

        const { copy, shape } = addElementByType(
            elementType,
            data,
            $svg,
            evt.clientX,
            evt.clientY
        );

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
        setSelectedElementId(shape.id);
    };

    const onClick = () => {
        setSelectedElementId(null);
    };

    return (
        <svg
            className="mx-auto"
            id={ data?.id }
            width={ data.width }
            height={ data.height }
            viewBox={ `${ data.x } ${ data.y } ${ data.width } ${ data.height }` }
            onClick={ onClick }
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            style={{
                backgroundColor: data.bgColor ?? `rgba(0, 0, 0, 0)`,
                backgroundImage: photoShopLikeBgImage,
                backgroundPosition: '-1px -1px'
            }}>

            {
                data?.children?.map(shape => {
                    return (
                        <Fragment key={ shape.id }>
                            {
                                (shape.type === DiagramElementType.RectangleWithText ||
                                 shape.type === DiagramElementType.RoundedRectangleWithText ||
                                 shape.type === DiagramElementType.Text) &&
                                <SvgRectangleWithText
                                    useStore={ useStore }
                                    shape={ shape as DiagramRectangleWithText }
                                />
                            }

                            {
                                (shape.type === DiagramElementType.Line ||
                                shape.type === DiagramElementType.Arrow) &&
                                <SvgArrow
                                    useStore={ useStore }
                                    shape={ shape as DiagramArrow }
                                />
                            }
                        </Fragment>
                    )
                })
            }


            <SvgSelection useStore={ useStore } />
        </svg>
    )
};

export default TodoDiagramSvg;