import { useFlowersStore } from '../../../data/flowers-store';
import PlantLocation from './plant-location';

const PlantLocationsList = () => {

    const plantLocationsData = useFlowersStore(store => store.plantLocationsData);

    return (
        <div>
            {
                plantLocationsData?.map(plantLocation => {
                    return (
                        <PlantLocation
                            key={ plantLocation.location_id }
                            plantLocation={ plantLocation }
                        />
                    )
                })
            }
        </div>
    )
};

export default PlantLocationsList;