import { Serie } from '@nivo/line';
import { ITodoTitleChartData, ITodoTitleChartDataItem, ITodoTitleChartResponse } from '../todo-title-chart-interfaces';

export const formatTodoTitleChartChartData = (response: ITodoTitleChartResponse) : ITodoTitleChartData|null => {
    if(!response) return null;

    const dataItems: ITodoTitleChartDataItem[] = [];
    const nestedTodos = response?.nestedTodos || [];

    for(const todo of nestedTodos) {
        const titleNumericValue = todo.todo_title.replace(/[^0-9.]/g, '').trim();
        if(titleNumericValue.length === 0) continue;

        const value = Number(titleNumericValue) || 0;

        dataItems.push({
            y: value, // the actual value, for example weight
            x: todo.todo_date, // time...
        });
    }

    return {
        parentTodo: response.todo,
        dataItems,
    };
};

export const generateGraphData = (dataItems: ITodoTitleChartDataItem[]): Serie[] => {

    // eslint-disable-next-line
    const graph = [{
        id: 'todoTitles',
        Datum: undefined,
        data: dataItems,
        color: 'hsl(2, 70%, 50%)',
    }] as Serie[];

    return graph;
};