import { ArrowLeftIcon } from '../../../../../../common/ui/icons';

interface IPrevYearButton {
    year: number;
    setYear: (year: number) => void;
}

const PrevYearButton = ({ year, setYear }: IPrevYearButton) => {

    const jumpTPrevMonth = () => {
        setYear(year - 1);
    };

    return (
        <button
            title="Previous Year"
            onClick={ jumpTPrevMonth }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevYearButton;