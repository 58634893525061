import { DiagramElement } from './diagram-element';
import { DiagramElementType } from './diagram-element-type-enum';
import { DiagramRectangleWithText } from './elements/diagram-rectangle-with-text';
import { DiagramLine } from './elements/diagram-line';
import { DiagramArrow } from './elements/diagram-arrow';
import { DiagramText } from './elements/diagram-text';

export class DiagramSvg {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    children?: DiagramElement[];
    bgColor?: string;

    constructor(
        id: string,
        x: number,
        y: number,
        width: number,
        height: number,
        children?: DiagramElement[],
        bgColor?: string,
    ) {
        this.id = id;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.children = children ?? [];
        this.bgColor = bgColor;
    }

    clone() : DiagramSvg {
        return new DiagramSvg(
            this.id,
            this.x,
            this.y,
            this.width,
            this.height,
            this.children,
            this.bgColor,
        );
    }

    toSvg() : string {

       const bgColor = this.bgColor ? ` style="background-color: ${ this.bgColor };" ` : '';

       try{
           return `<svg
                class="max-w-full mx-auto" ${ bgColor }
                width="${ this.width }"
                height="${ this.height }"
                viewBox="${ this.x } ${ this.y } ${ this.width } ${ this.height }">
                ${  this.children.map(shape => shape.toSvg() ).join('')  }
            </svg>`;
       }
       catch (ex) {
           console.log(ex);
           return '';
       }
    }

    getSvgCenter() : { cx: number, cy: number } {
        const w = this.width - this.x;
        const h = this.height - this.y;
        const cx = this.x + w/2;
        const cy = this.y + h/2;

        return {
            cx,
            cy,
        };
    }

    removeGuideLines = () => {
        if(!this?.children) return;

        const children: DiagramElement[] = [];

        for(const child of this.children) {
            if(!child.id.startsWith('guide-')) {
                children.push(child);
            }
        }

        this.children = children;
    }

    public static fromJson(json: string): DiagramSvg {
        try{
            const parsed = json ? JSON.parse(json) : null;
            if(!parsed) return null;

            return new DiagramSvg(
                parsed.id,
                parsed.x,
                parsed.y,
                parsed.width,
                parsed.height,
                parsed.children?.map((shape: DiagramElement) => {
                    switch (shape.type) {
                        case DiagramElementType.RoundedRectangleWithText: {
                            return DiagramRectangleWithText.fromJson(shape as DiagramRectangleWithText);
                        }
                        case DiagramElementType.RectangleWithText: {
                            return DiagramRectangleWithText.fromJson(shape as DiagramRectangleWithText);
                        }
                        case DiagramElementType.Text: {
                            return DiagramText.fromJson(shape as DiagramText);
                        }
                        case DiagramElementType.Line: {
                            return DiagramLine.fromJson(shape as DiagramLine);
                        }
                        case DiagramElementType.Arrow: {
                            return DiagramArrow.fromJson(shape as DiagramArrow);
                        }
                    }
                }),
                parsed.bgColor,
            );
        }
        catch (ex) {
            console.error(ex);
            return null;
        }
    }
}
