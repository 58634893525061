import { ArrowRightIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';

const NextYearButton = () => {

    const currentCalendarYear = useFoodStore(store => store.currentCalendarYear);
    const setCurrentCalendarYear = useFoodStore(store => store.setCurrentCalendarYear);

    const jumpToNextMonth = () => {
        setCurrentCalendarYear(currentCalendarYear + 1);
    };

    return (
        <button
            title="Next Year"
            onClick={ jumpToNextMonth }
            type="button">
            <ArrowRightIcon />
        </button>
    )
};

export default NextYearButton;