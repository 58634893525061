import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import { IPlantType } from '../../../flowers-interfaces';
import DeletePlantType from '../actions/delete-plant-type';
import EditPlantType from '../actions/edit-plant-type';
import PlantTypeColorIndicator from '../plant-type-color-indicator';

interface IPlantTypeProps {
    plantType: IPlantType;
}

enum Mode {
    Default = 0,
    Edit = 1,
}

const PlantType = ({ plantType }: IPlantTypeProps) => {

    const [mode, setMode] = useState<Mode>(Mode.Default);

    const startEdit = () => {
        setMode(Mode.Edit);
    };

    const stopEdit = () => {
        setMode(Mode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === Mode.Default &&
                (
                    <>
                        <div className="flex items-center">
                            <PlantTypeColorIndicator plantType={ plantType } />
                            <button type="button" onClick={ startEdit }>{ plantType.type_name }</button>

                            <Actions classes="plant-type__actions" mr="mr-0" gridSize={ 2 }>
                                <DeletePlantType plantType={ plantType } />

                                <button
                                    title="Edit Plant Type"
                                    className="edit-plant-type flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 } />
                                </button>
                            </Actions>
                        </div>

                        {
                            plantType.type_desc &&
                            <div className="ml-7 text-gray-500">{ plantType.type_desc }</div>
                        }
                    </>
                )
            }

            {
                mode === Mode.Edit &&
                <EditPlantType
                    plantType={ plantType }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default PlantType;