import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getPlantsData } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';
import PlantsList from './list/plants-list';
import PlantTypeTabsNavigation, { PlantsTab } from './tabs-navigation';
import AddNewPlant from './actions/add-new-plant';

const PlantsHome = () => {

    const plantsTab = useFlowersStore(store => store.plantsTab);
    const setPlantsData = useFlowersStore(store => store.setPlantsData);

    useEffect(() => {
        document.title = `Plants | Flowers`;
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getPlantsData();

            if(!response) {
                showToast('Get plants error.');
                return;
            }

            setPlantsData(response);
        })();
    }, [setPlantsData]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Plants
            </h1>

            <PlantTypeTabsNavigation />

            {
                plantsTab === PlantsTab.Default &&
                <PlantsList />
            }

            {
                plantsTab === PlantsTab.AddNew &&
                <AddNewPlant />
            }

        </div>
    )
};

export default PlantsHome;