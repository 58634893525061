import MonthView from './month-view';
import { useFlowersStore } from '../../data/flowers-store';

interface IYearView {
    plant_id: number;
}

const YearView = ({ plant_id }: IYearView) => {

    const calendarData = useFlowersStore(store => store.calendarData);

    return (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 border-b border-r mt-4">
            {
                calendarData?.months?.map(month => {
                    return (
                        <MonthView
                            key={ month.id }
                            month={ month }
                            plant_id={ plant_id }
                        />
                    )
                })
            }
        </div>
    )
};

export default YearView;