import { useFlowersStore } from '../../../data/flowers-store';
import Plant from './plant';

const PlantsList = () => {

    const plantsData = useFlowersStore(store => store.plantsData);

    return (
        <div>
            {
                plantsData?.plants?.map(plant => {
                    return (
                        <Plant
                            key={ plant.plant_id }
                            plant={ plant }
                        />
                    )
                })
            }
        </div>
    )
};

export default PlantsList;