import { deleteJson, get, postJson, putJson, upload } from '../../../common/data/common-data-service';
import { IFlowersResponse, IPlantCalendarResponse, IPlantLocation, IPlantType } from '../flowers-interfaces';
import { formatFlowersResponse, PlantLifeCycle } from '../domain/flowers-provider';

// --------------- PLANT TYPES -----------------------

export const getPlantTypesData = async () : Promise<IPlantType[]> => {
    return await get(`/api/flowers/plant-type/get-data`);
};

export const insertPlantType = async (
    name: string,
    desc: string,
    color: string,
    base_watering_frequency: number,
    seasonal_weight: number,
    temperature_weight: number,
    humidity_weight: number,
) : Promise<IPlantType[]> => {
    return await postJson(`/api/flowers/plant-type/insert`, {
        name,
        desc,
        color,
        base_watering_frequency,
        seasonal_weight,
        temperature_weight,
        humidity_weight,
    });
};

export const updatePlantType = async (
    id: number,
    name: string,
    desc: string,
    color: string,
    base_watering_frequency: number,
    seasonal_weight: number,
    temperature_weight: number,
    humidity_weight: number,
) : Promise<IPlantType[]> => {
    return await putJson(`/api/flowers/plant-type/update`, {
        id,
        name,
        desc,
        color,
        base_watering_frequency,
        seasonal_weight,
        temperature_weight,
        humidity_weight,
    });
};

export const deletePlantType = async (id: number) : Promise<IPlantType[]> => {
    return await deleteJson(`/api/flowers/plant-type/delete`, {
        id
    });
};

// --------------- PLANT LOCATIONS -----------------------

export const getPlantLocationsData = async () : Promise<IPlantLocation[]> => {
    return await get(`/api/flowers/plant-location/get-data`);
};

export const insertPlantLocations = async (
    name: string,
    desc: string,
    light_intensity: number,
    is_indoor: boolean
) : Promise<IPlantLocation[]> => {
    return await postJson(`/api/flowers/plant-location/insert`, {
        name,
        desc,
        light_intensity,
        is_indoor,
    });
};

export const updatePlantLocation = async (
    location_id: number,
    name: string,
    desc: string,
    light_intensity: number,
    is_indoor: boolean
) : Promise<IPlantLocation[]> => {
    return await putJson(`/api/flowers/plant-location/update`, {
        location_id,
        name,
        desc,
        light_intensity,
        is_indoor,
    });
};

export const deletePlantLocation = async (location_id: number) : Promise<IPlantLocation[]> => {
    return await deleteJson(`/api/flowers/plant-location/delete`, {
        location_id,
    });
};

// --------------- PLANTS -----------------------

export const getPlantsData = async () : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await get(`/api/flowers/plant/get-data`));
};

export const insertPlant = async (
    name: string,
    desc: string,
    plant_type_id: number,
    plant_location_id: number,
    plant_life_cycle_type: PlantLifeCycle,
    is_alive: boolean,
    quantity: number,
    wateringDate: number|null,
) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await postJson(`/api/flowers/plant/insert`, {
        name,
        desc,
        plant_type_id,
        plant_location_id,
        plant_life_cycle_type,
        is_alive: is_alive ? 1 : 0,
        quantity,
        wateringDate,
    }));
};

export const updatePlant = async (
    plantId: number,
    name: string,
    desc: string,
    plant_type_id: number,
    plant_location_id: number,
    plant_life_cycle_type: PlantLifeCycle,
    is_alive: boolean,
    quantity: number,
    wateringDate: number|null,
) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await putJson(`/api/flowers/plant/update`, {
        plantId,
        name,
        desc,
        plant_type_id,
        plant_location_id,
        plant_life_cycle_type,
        is_alive: is_alive ? 1 : 0,
        quantity,
        wateringDate,
    }));
};

export const deletePlant = async (plantId: number) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await deleteJson(`/api/flowers/plant/delete`, {
        plantId,
    }));
};

// --------------- PLANT ATTACHMENTS -----------------------

export const uploadPlantAttachment = async (formData: FormData) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await upload('/api/flowers/attachment/upload', formData));
};

export const deletePlantAttachment = async (
    plant_attachment_id: number,
) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await deleteJson(`/api/flowers/attachment/delete`, {
        plant_attachment_id,
    }));
};

export const updatePlantAttachmentDescription = async (attachment_id: number, new_desc: string) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await putJson(`/api/flowers/attachment/edit-desc`, {
        attachment_id,
        new_desc,
    }));
};

// --------------- WATER LOGS -----------------------

export const getWaterLogsData = async () : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await get(`/api/flowers/log/get-data`));
};

export const trackWaterLog = async (
    today_end: number,
    plant_id: number,
    last_watered: number,
    next_watering: number,
    desc: string,
) : Promise<IFlowersResponse> => {
    return formatFlowersResponse(await postJson(`/api/flowers/log/track-log`, {
        today_end,
        plant_id,
        last_watered,
        next_watering,
        desc,
    }));
};

// --------------- PLANT CALENDAR -----------------------

export const getPlantCalendarData = async (plant_id: number) : Promise<IPlantCalendarResponse> => {
    return await get(`/api/flowers/plant-calendar/get-data?plant_id=${ plant_id }`);
};

export const addWaterLog = async (
    plant_id: number,
    date_time: number,
) : Promise<IPlantCalendarResponse> => {
    return await postJson(`/api/flowers/plant-calendar/add-water-log`, {
        plant_id,
        date_time,
    })
};

export const deleteWaterLog = async (
    plant_id: number,
    date_time: number,
) : Promise<IPlantCalendarResponse> => {
    return await deleteJson(`/api/flowers/plant-calendar/delete-water-log`, {
        plant_id,
        date_time,
    })
};


