import { addDays, format, startOfDay } from 'date-fns';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useRef } from 'react';
import { CalendarIcon2, ResetIcon, SunIcon } from '../../../../../common/ui/icons';

interface IEditWateringDate {
    date: number|null,
    setDate: (date: number) => void;
    onKeyDown?: (evt: ReactKeyboardEvent<HTMLDivElement>) => Promise<void>;
}

const EditWateringDate = ({ date, setDate, onKeyDown } : IEditWateringDate) => {

    const contentRef = useRef<HTMLInputElement>(null);

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = startOfDay(new Date(evt.target.value));
        setDate(_date.getTime());
    };

    const setToday = () => {
        const today = startOfDay(new Date());
        setDate(today.setHours(0,0,0,0));
    };

    const setTomorrow = () => {
        const today = startOfDay(new Date());
        today.setHours(0,0,0,0);

        const tomorrow = addDays(today, 1);
        setDate(tomorrow.getTime());
    };

    const setNoDate = () => {
        setDate(null);
    };

    return (
        <div className="flex flex-col xl:flex-row">
            <div className="flex items-center">
                <div className="flex flex-col mr-4">
                    <label className="font-bold mb-1 text-slate-400">Watering Date</label>

                    <input
                        ref={ contentRef }
                        type="date"
                        className="border rounded text-slate-800 outline-stone-200 mb-4 px-4 py-2 text-sm"
                        onKeyDown={ onKeyDown }
                        onChange={ onDateChange }
                        value={ date ? format(date, `yyyy-MM-dd`) : '' }
                    />
                </div>

            </div>

            <div className="flex items-center my-4 xl:mb-0">
                <button
                    onClick={ setToday }
                    className="text-xs flex items-center mr-4"
                    type="button">
                    <CalendarIcon2 size={ 16 } color={ '#42a378' } classes="mr-1"/>
                    Today
                </button>

                <button
                    onClick={ setTomorrow }
                    className="text-xs flex items-center mr-4 "
                    type="button">
                    <SunIcon size={ 16 } color={ '#e18601' } classes="mr-1"/>
                    Tomorrow
                </button>

                <button
                    onClick={ setNoDate }
                    className="text-xs flex items-center mr-4"
                    type="button">
                    <ResetIcon size={ 16 } classes="mr-1"/>
                    Reset
                </button>
            </div>
        </div>
    )
};

export default EditWateringDate;