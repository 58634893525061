import { PlantLifeCycle } from '../../domain/flowers-provider';
import { ClockIcon } from '../../../../common/ui/icons';

interface IPlantLifecycleText {
    plantLifeCycleType: PlantLifeCycle;
}

const PlantLifecycleText = ({ plantLifeCycleType } : IPlantLifecycleText) => {
    return (
        <>
            {
                !!plantLifeCycleType &&
                <div className="text-sm flex items-center">
                    <ClockIcon classes="mr-1" size={ 20 } />
                    { plantLifeCycleType === PlantLifeCycle.Annual && <div>Однолетнее</div> }
                    { plantLifeCycleType === PlantLifeCycle.Biennial && <div>Двухлетнее</div> }
                    { plantLifeCycleType === PlantLifeCycle.Perennial && <div>Многолетнее</div> }
                    { plantLifeCycleType === PlantLifeCycle.Ephemeral && <div>Несколько Недель</div> }
                    { plantLifeCycleType === PlantLifeCycle.Herbaceous &&
                        <div>Отмирает до уровня земли каждый сезон</div> }
                </div>
            }
        </>
    )
};

export default PlantLifecycleText;