import { usePlannerStore } from '../../../data/planner-store';
import { getTagCode } from '../../../domain/tags-provider';

interface ITagFilter {
    title: string;
    todosCount: number;
}

const TagFilter = ({ title, todosCount }: ITagFilter) => {

    const selectedFilterTags = usePlannerStore(state => state.selectedFilterTags);
    const setSelectedFilterTags = usePlannerStore(state => state.setSelectedFilterTags);
    const code = getTagCode(title);

    const onChange = () => {
        const copy = new Set<string>(selectedFilterTags);

        if(copy.has(code)) {
            copy.delete(code);
        }
        else{
            copy.add(code);
        }

        setSelectedFilterTags(copy);
    };
    
    return (
        <label>
            <input
                onChange={ onChange }
                className="filter-tag-checkbox hidden"
                type="checkbox"
                value={ code }
                checked={ selectedFilterTags.has(code) }
            />
            <span className="filter-tag-title mr-2 mb-1 bg-slate-100 rounded px-2 py-1 block pointer flex items-center">
                <span>{ title }</span>
                <span className="text-xs ml-1">({ todosCount })</span>
            </span>
        </label>
    )
};

export default TagFilter;