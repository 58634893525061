import { DiagramElement, DUPLICATE_SHAPE_OFFSET_X, DUPLICATE_SHAPE_OFFSET_Y } from '../diagram-element';
import { DiagramElementType } from '../diagram-element-type-enum';
import { DiagramArrow } from './diagram-arrow';
import { v4 as uuidv4 } from 'uuid';

export interface IDiagramRectangleSelectionCircle {
    index: number;
    cx: number;
    cy: number;
    lineEndX: number;
    lineEndY: number;
    cursor: string;
}

export const getTextAlignClassesByType = (textAlign: string) => {
    if(textAlign === 'left') {
        return 'items-start justify-center text-left';
    }

    if(textAlign === 'right') {
        return 'items-end justify-center text-right';
    }

    return 'items-center justify-center text-center';
};

export class DiagramRectangleWithText extends DiagramElement {

    public static readonly defaultText = 'text';
    public static readonly defaultWidth = 120;
    public static readonly defaultHeight = 60;
    public static readonly defaultRadius = 9;
    public static readonly defaultFill = '#ffffff';
    public static readonly defaultStroke = '#000000';
    public static readonly defaultStrokeWidth = 1;
    public static readonly defaultTextColor = '#000000';
    public static readonly defaultTextFontSize = '1rem';

    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
    fill: string;
    stroke: string;
    strokeWidth: number;
    rx?: number;
    ry?: number;
    textColor?: string;
    fontSize?: string;
    isBold?: boolean;
    isItalic?: boolean;
    isUnderline?: boolean;
    textAlign?: string;

    constructor(
        id: string,
        text: string,
        x: number,
        y: number,
        width: number,
        height: number,
        fill: string,
        stroke: string,
        strokeWidth: number,
        rx?: number,
        ry?: number,
        textColor?: string,
        fontSize?: string,
        isBold?: boolean,
        isItalic?: boolean,
        isUnderline?: boolean,
        textAlign?: string,
    ) {
        super(id, DiagramElementType.RectangleWithText);

        this.text = text;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.fill = fill;
        this.stroke = stroke;
        this.strokeWidth = strokeWidth;
        this.rx = rx;
        this.ry = ry;
        this.textColor = textColor;
        this.fontSize = fontSize;
        this.isBold = isBold;
        this.isItalic = isItalic;
        this.isUnderline = isUnderline;
        this.textAlign = textAlign;
    }

    clone(): DiagramRectangleWithText {
        return new DiagramRectangleWithText(
            this.id,
            this.text,
            this.x,
            this.y,
            this.width,
            this.height,
            this.fill,
            this.stroke,
            this.strokeWidth,
            this.rx,
            this.ry,
            this.textColor,
            this.fontSize,
            this.isBold,
            this.isItalic,
            this.isUnderline,
            this.textAlign,
        );
    }

    duplicate(): DiagramRectangleWithText {
        const copy = this.clone();
        copy.id = uuidv4();

        copy.x += DUPLICATE_SHAPE_OFFSET_X;
        copy.y += DUPLICATE_SHAPE_OFFSET_Y;

        return copy;
    }

    toSvg(): string {
        return `
            <g data-type="rectangle">
                <rect
                    x="${ this.x }"
                    y="${ this.y }"
                    width="${ this.width }"
                    height="${ this.height }"
                    fill="${ this.fill }"
                    stroke="${ this.stroke }"
                    stroke-width="${ this.strokeWidth ?? DiagramRectangleWithText.defaultStrokeWidth }"
                    rx="${ this.rx }"
                    ry="${ this.ry }"
                />
    
                <foreignObject
                    x="${ this.x }"
                    y="${ this.y }"
                    width="${ this.width }"
                    height="${ this.height }">
                    <div
                        class="flex flex-col ${ getTextAlignClassesByType(this.textAlign) } w-full h-full p-2"
                        style="color: ${ this.textColor || DiagramRectangleWithText.defaultTextColor }; 
                               font-size: ${ this.fontSize || DiagramRectangleWithText.defaultTextFontSize };
                               ${ this.isBold ? 'font-weight: bold;' : '' }
                               ${ this.isItalic ? 'font-style: italic;' : '' }
                               ${ this.isUnderline ? 'text-decoration: underline;' : '' }">
                        ${ this.text }
                    </div>
                </foreignObject>
            </g>
        `;
    }

    getSelectionCircles() : IDiagramRectangleSelectionCircle[] {

        const lineLength = DiagramArrow.defaultSize;

        return [
            // Top-left
            {
                index: 0,
                cx: this.x,
                cy: this.y,
                lineEndX: this.x - lineLength,
                lineEndY: this.y - lineLength,
                cursor: 'nw-resize',
            },
            // Top-center
            {
                index: 1,
                cx: this.x + this.width / 2,
                cy: this.y,
                lineEndX: this.x + this.width / 2,
                lineEndY: this.y - lineLength,
                cursor: 'n-resize',
            },
            // Top-right
            {
                index: 2,
                cx: this.x + this.width,
                cy: this.y,
                lineEndX: this.x + this.width + lineLength,
                lineEndY: this.y - lineLength,
                cursor: 'ne-resize',
            },
            // Middle-left
            {
                index: 3,
                cx: this.x,
                cy: this.y + this.height / 2,
                lineEndX: this.x - lineLength,
                lineEndY: this.y + this.height / 2,
                cursor: 'w-resize',
            },
            // Middle-right
            {
                index: 4,
                cx: this.x + this.width,
                cy: this.y + this.height / 2,
                lineEndX: this.x + this.width + lineLength,
                lineEndY: this.y + this.height / 2,
                cursor: 'e-resize',
            },
            // Bottom-left
            {
                index: 5,
                cx: this.x,
                cy: this.y + this.height,
                lineEndX: this.x - lineLength,
                lineEndY: this.y + this.height + lineLength,
                cursor: 'sw-resize',
            },
            // Bottom-center
            {
                index: 6,
                cx: this.x + this.width / 2,
                cy: this.y + this.height,
                lineEndX: this.x + this.width / 2,
                lineEndY: this.y + this.height + lineLength,
                cursor: 's-resize',
            },
            // Bottom-right
            {
                index: 7,
                cx: this.x + this.width,
                cy: this.y + this.height,
                lineEndX: this.x + this.width + lineLength,
                lineEndY: this.y + this.height + lineLength,
                cursor: 'se-resize',
            },
        ];
    }

    getPoints() {
        return {
            leftX: Math.round(this.x),
            rightX: Math.round(this.x + this.width),

            topY: Math.round(this.y),
            bottomY: Math.round(this.y + this.height),

            centerX: Math.round(this.x + this.width / 2),
            centerY: Math.round(this.y + this.height / 2),
        }
    }

    public static fromJson(json: DiagramRectangleWithText): DiagramElement {
        return new DiagramRectangleWithText(
            json.id,
            json.text,
            json.x,
            json.y,
            json.width,
            json.height,
            json.fill,
            json.stroke,
            json.strokeWidth,
            json.rx,
            json.ry,
            json.textColor,
            json.fontSize,
            json.isBold,
            json.isItalic,
            json.isUnderline,
            json.textAlign,
        );
    }
}