import { DiagramElement, DUPLICATE_SHAPE_OFFSET_X, DUPLICATE_SHAPE_OFFSET_Y } from '../diagram-element';
import { DiagramElementType } from '../diagram-element-type-enum';
import { v4 as uuidv4 } from 'uuid';

export class DiagramLine extends DiagramElement {
    public static readonly defaultSize = 50;

    x1: number;
    y1: number;
    x2: number;
    y2: number;
    stroke?: string;
    strokeWidth?: number;

    constructor(
        id: string,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        stroke?: string,
        strokeWidth?: number,
    ) {
        super(id, DiagramElementType.Line);

        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.stroke = stroke;
        this.strokeWidth = strokeWidth;
    }

    clone(): DiagramLine {
        return new DiagramLine(
            this.id,
            this.x1,
            this.y1,
            this.x2,
            this.y2,
            this.stroke,
            this.strokeWidth,
        )
    }

    duplicate(): DiagramLine {
        const copy = this.clone();
        copy.id = uuidv4();

        copy.x1 += DUPLICATE_SHAPE_OFFSET_X;
        copy.y1 += DUPLICATE_SHAPE_OFFSET_Y;
        copy.x2 += DUPLICATE_SHAPE_OFFSET_X;
        copy.y2 += DUPLICATE_SHAPE_OFFSET_Y;

        return copy;
    }

    toSvg(): string {

        return `
            <g data-type="line">
                <line
                    x1="${ this.x1 }"
                    y1="${ this.y1 }"
                    x2="${ this.x2 }"
                    y2="${ this.y2 }"
                    stroke="${ this.stroke }"
                    stroke-width="${ this.strokeWidth }"
                />
            </g>
        `;
    }

    public static fromJson(json: DiagramLine): DiagramLine {
        return new DiagramLine(
            json.id,
            json.x1,
            json.y1,
            json.x2,
            json.y2,
            json.stroke,
            json.strokeWidth,
        );
    }
}