import { DiagramArrow } from '../../../domain/diagram-elements/elements/diagram-arrow';
import { TodoDiagramUseStore } from '../../../data/store';
import LineSelection from './selection/line-selection';
import { MouseEvent, PointerEvent as ReactPointerEvent, useRef } from 'react';
import { DiagramLine } from '../../../domain/diagram-elements/elements/diagram-line';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { mapToSvgCoordinates, tryGetSvgElement, updateHistory } from '../../../domain/todo-diagram-provider';

interface ISvgRectangle {
    useStore: TodoDiagramUseStore;
    shape: DiagramArrow|DiagramLine;
}

const SvgArrow = ({ useStore, shape }: ISvgRectangle) => {

    const arrowHeadId = `arrow-head-${ shape.id }`;

    const selectedElementId = useStore(state => state.selectedElementId);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);
    const isSelected = selectedElementId === shape?.id;

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === shape.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const isDraggingRef = useRef(false);
    const dragOffsetX1Ref = useRef(0);
    const dragOffsetY1Ref = useRef(0);
    const dragOffsetX2Ref = useRef(0);
    const dragOffsetY2Ref = useRef(0);

    const onClick = (evt: MouseEvent) => {
        evt.stopPropagation();
        setSelectedElementId(shape.id);
    };

    const onDrag = (evt: PointerEvent) => {
        if (!isDraggingRef?.current) return;

        const $svg = tryGetSvgElement(evt.target);
        if(!$svg) return;

        const transformedPoint = mapToSvgCoordinates($svg, evt.clientX, evt.clientY);

        // Update the rectangle's position
        const newX1 = transformedPoint.x - dragOffsetX1Ref.current;
        const newY1 = transformedPoint.y - dragOffsetY1Ref.current;
        const newX2 = transformedPoint.x - dragOffsetX2Ref.current;
        const newY2 = transformedPoint.y - dragOffsetY2Ref.current;

        const updatedShape = shape.clone();
        updatedShape.x1 = newX1;
        updatedShape.y1 = newY1;
        updatedShape.x2 = newX2;
        updatedShape.y2 = newY2;

        const copy = data.clone();
        copy.children[foundIndex] = updatedShape;
        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGLineElement>) => {

        evt.preventDefault();

        if(foundIndex === -1) return;

        isDraggingRef.current = true;

        const $svg = tryGetSvgElement(evt.currentTarget);
        if(!$svg) return;

        const transformedPoint = mapToSvgCoordinates($svg, evt.clientX, evt.clientY);

        dragOffsetX1Ref.current = transformedPoint.x - shape.x1;
        dragOffsetY1Ref.current = transformedPoint.y - shape.y1;
        dragOffsetX2Ref.current = transformedPoint.x - shape.x2;
        dragOffsetY2Ref.current = transformedPoint.y - shape.y2;

        const onPointerMove = (moveEvent: PointerEvent) => {
            onDrag(moveEvent);
        };

        const onPointerUp = () => {
            isDraggingRef.current = false;
            setDataStack(updateHistory(dataStack, data));
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
    };
    
    const arrow = shape as DiagramArrow;

    return (
        <g data-type={ shape.type === DiagramElementType.Arrow ? 'arrow' : 'line' }>
            {
                (shape.type === DiagramElementType.Arrow) &&
                <defs>
                    <marker
                        id={ arrowHeadId }
                        markerWidth={ arrow.arrowheadSize }
                        markerHeight={ arrow.arrowheadSize }
                        refX={ arrow.arrowheadSize }
                        refY={ arrow.arrowheadSize / 2 }
                        orient="auto"
                        markerUnits="userSpaceOnUse">
                        <path
                            d={ `M 0 0 L ${ arrow.arrowheadSize } ${ arrow.arrowheadSize / 2 } L 0 ${ arrow.arrowheadSize }` }
                            fill="none"
                            stroke={ shape.stroke }
                            strokeWidth={ shape.strokeWidth }
                            strokeLinejoin="round"
                            strokeLinecap="round"
                        />
                    </marker>
                </defs>
            }

            {/* Invisible clickable area */ }
            <line
                onPointerDown={ onPointerDown }
                onClick={ onClick }
                className="cursor-move"
                x1={ shape.x1 }
                y1={ shape.y1 }
                x2={ shape.x2 }
                y2={ shape.y2 }
                stroke="transparent"
                strokeWidth={ 10 } // Larger stroke width for easier clicking
                pointerEvents="stroke" // Enable click events on the invisible stroke
            />

            {/* Visible line */ }
            <line
                onPointerDown={ onPointerDown }
                onClick={ onClick }
                className="cursor-move"
                x1={ shape.x1 }
                y1={ shape.y1 }
                x2={ shape.x2 }
                y2={ shape.y2 }
                stroke={ shape.stroke }
                strokeWidth={ shape.strokeWidth }
                markerEnd={ `url(#${ arrowHeadId })` }
            />

            {
                isSelected &&
                <LineSelection
                    useStore={ useStore }
                    shape={ shape }
                />
            }
        </g>
    )
};

export default SvgArrow;