import { useFlowersStore } from '../../../data/flowers-store';
import PlantType from './plant-type';

const PlantTypesList = () => {

    const plantTypesData = useFlowersStore(store => store.plantTypesData);

    return (
        <div>
            {
                plantTypesData?.map(plantType => {
                    return (
                        <PlantType
                            key={ plantType.type_id }
                            plantType={ plantType }
                        />
                    )
                })
            }
        </div>
    )
};

export default PlantTypesList;