import { useFlowersStore } from '../../data/flowers-store';

export enum PlantsTab {
    Default = 0,
    AddNew = 1,
}

const PlantsTabsNavigation = () => {

    const plantsTab = useFlowersStore(store => store.plantsTab);
    const setPlantsTab = useFlowersStore(store => store.setPlantsTab);

    const openListTab = () => {
        setPlantsTab(PlantsTab.Default);
    };

    const openAddNewTab = () => {
        setPlantsTab(PlantsTab.AddNew);
    };

    return (
        <nav className="flex border-b-8 mb-8 border-slate-400 rounded-r-md">
            <button
                onClick={ openListTab }
                className={ `border-t border-l border-r px-10 py-2 mr-2 rounded-t-md border-slate-400 ${ plantsTab === PlantsTab.Default ? 'bg-slate-400 text-white' : '' }` }
                type="button">
                List
            </button>

            <button
                onClick={ openAddNewTab }
                className={ `border-t border-l border-r px-6 py-2 rounded-t-md border-slate-400 ${ plantsTab === PlantsTab.AddNew ? 'bg-slate-400 text-white' : '' }` }
                type="button">
                Add New
            </button>
        </nav>
    )
};

export default PlantsTabsNavigation;