import { ITodo } from '../../planner-interfaces';
import { ADD_NEW_TODO_DESCRIPTION_EVENT } from './description/todo-description';

interface ITodoTagsProps {
    todo: ITodo;
}

const TodoTags = ({ todo }: ITodoTagsProps) => {

    const hasNestedTodos = todo?.nested?.length > 0;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_DESCRIPTION_EVENT, {
            detail: todo.todo_id,
        }));
    };

    return (
        <div className={ `text-xs flex items-center ml-6 md:mt-1 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
            {
                todo.tags.map(tag => {
                    return (
                        <button
                            type="button"
                            className="mr-2 bg-slate-100 whitespace-nowrap rounded px-4 py-1 mb-1"
                            onClick={ sendEvent }
                            key={ tag.code }>
                            { tag.title }
                        </button>
                    )
                })
            }
        </div>
    )
};

export default TodoTags;