import { useCallback } from 'react';
import { TodoDescriptionMode } from './description/todo-description';
import { IGeneralResponse, ITodo, TodoType } from '../../planner-interfaces';
import { combineProjectData } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';
import CommonTodoEdit from '../../../../common/ui/todo/common-todo-edit';
import { updateTodo } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';

interface IEditTodoProps {
    todo: ITodo;
    setMode: (mode: TodoDescriptionMode) => void;
}

const EditTodo = ({ todo, setMode }: IEditTodoProps) => {

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const setLongMode = useCallback(() => {
        setMode(TodoDescriptionMode.DEFAULT_LONG);
    }, [setMode]);

    const save = async (
        todo_id: number | string,
        new_title: string,
        new_desc: string,
        todo_address: string,
        todo_date: number | null,
        todo_date_has_time: number,
        todo_is_active: number,
        show_in_overview: number,
        todo_type: TodoType,
        update_nested: boolean,
        tags: string,
        ideal_final_result: string,
        habit_active?: boolean,
        habit_interval?: number,
        habit_qty_per_interval?: number,
        habit_week_days?: string,
    ) => {

        const response = await updateTodo(
            todo_id,
            new_title.trim(),
            new_desc.trim(),
            todo_address,
            todo_date,
            todo_date_has_time ? 1 : 0,
            todo_is_active ? 1 : 0,
            show_in_overview ? 1 : 0,
            todo_type,
            update_nested,
            tags,
            ideal_final_result,
            habit_active,
            habit_interval,
            habit_qty_per_interval,
            habit_week_days,
        ) as IGeneralResponse;

        if (!response) {
            showToast('Update task error.');
            return;
        }

        setLongMode();

        setCombinedProject(combineProjectData(response));
    };

    const onClose = () => {
        setLongMode();
    };

    return (
        <CommonTodoEdit
            todo={ todo }
            allTags={ combinedProject?.tags || [] }
            saveCallback={ save }
            onClose={ onClose }
            classes={ todo.nested?.length > 0 ? 'ml-8 xl:ml-14' : 'ml-6' }
            shouldEditTitle={ false }
        />
    )
};

export default EditTodo;