import { useAuthStatus } from '../../auth/hooks/auth-status-hook';
import { useEffect, MouseEvent } from 'react';
import Preloader from '../../../common/ui/preloader';
import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import Menu from '../../../menu/menu';
import FoodMenu from './food-menu';
import { useLocation } from 'react-router-dom';
import FoodCategoriesHome from './categories/categories-home';
import FoodItemsHome from './foods/food-items-home';
import FoodLogHome from './food-log/food-log-home';
import FoodCalendarHome from './food-calendar/food-calendar-home';
import ExportFoodDataHome from './export/export-home';

const FoodHome = () => {

    const { loading } = useAuthStatus();

    const { pathname } = useLocation();

    useEffect(() => {
        document.title = 'Food | Productivity';
    }, []);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <FoodMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 pb-10">
                            {
                                pathname === '/food' && <FoodLogHome />
                            }

                            {
                                pathname === '/food/food-items' && <FoodItemsHome />
                            }

                            {
                                pathname === '/food/categories' && <FoodCategoriesHome />
                            }

                            {
                                pathname === '/food/calendar' && <FoodCalendarHome />
                            }

                            {
                                pathname === '/food/export' && <ExportFoodDataHome />
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default FoodHome;