import PrevYearButton from './actions/prev-year-button';
import NextYearButton from './actions/next-year-button';
import TodayButton from './actions/today-button';
import { useFlowersStore } from '../../../data/flowers-store';

const PantWaterCalendarTitle = () => {

    const year = useFlowersStore(store => store.year);
    const setYear = useFlowersStore(store => store.setYear);
    const calendarData = useFlowersStore(store => store.calendarData);

    return (
        <>
            {
                calendarData &&
                <div className="flex flex-col md:flex-row items-center">
                    <div className="text-xl xl:text-3xl flex items-center text-indigo-700">
                        { year }
                    </div>

                    <div className="calendar__actions flex items-center ml-6">
                        <TodayButton setYear={ setYear } />

                        <div className="flex items-center mx-6">
                            <PrevYearButton year={year} setYear={ setYear } />
                            <NextYearButton year={year} setYear={ setYear } />
                        </div>
                    </div>

                </div>
            }
        </>
    )
};

export default PantWaterCalendarTitle;