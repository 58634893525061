import { DiagramElementType } from './diagram-element-type-enum';

export const DUPLICATE_SHAPE_OFFSET_X = 15;
export const DUPLICATE_SHAPE_OFFSET_Y = 15;

export abstract class DiagramElement {
    id: string;
    type: DiagramElementType;

    protected constructor(
        id: string,
        type: DiagramElementType,
    ) {
        this.id = id;
        this.type = type;
    }

    abstract clone() : DiagramElement;
    abstract duplicate() : DiagramElement;
    abstract toSvg() : string;
}
