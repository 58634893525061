import { SunIcon } from '../../../../../../common/ui/icons';

interface ITodayButton {
    setYear: (year: number) => void;
}

const TodayButton = ({ setYear }: ITodayButton) => {

    const jumpToday = () => {
        setYear((new Date()).getFullYear());
    };

    return (
        <button
            title="Current Year"
            onClick={ jumpToday }
            className="flex items-center"
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mr-1"
            />
            Today
        </button>
    )
};

export default TodayButton;