import { TodoDiagramUseStore } from '../data/store';
import TodoDiagramSvg from './svg/svg';

export interface ISceneProps {
    useStore: TodoDiagramUseStore;
}

const Scene = ({ useStore }: ISceneProps) => {

    return (
        <div
            className="overflow-auto bg-white"
            style={{
                width: '100%',
            }}>
            <TodoDiagramSvg useStore={ useStore } />
        </div>
    )
};

export default Scene;