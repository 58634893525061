import { LightIntensity } from '../../domain/flowers-provider';
import { ChangeEvent } from 'react';

interface ILightIntensitySelect {
    value: LightIntensity;
    onSelect: (_value: LightIntensity) => void;
}

const LightIntensitySelect = ({ value, onSelect }: ILightIntensitySelect) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _value = Number(evt.target.value) || LightIntensity.BrightLight;
        onSelect(_value);
    };

    return (
        <select
            className="border rounded px-4 py-2"
            value={ value }
            onChange={ onChange }>
            <option value={ LightIntensity.LowLight }>Минимальное непрямое освещение (затененные углы в помещении)</option>
            <option value={ LightIntensity.MediumLight }>Умеренный непрямой свет (возле окна с занавесками)</option>
            <option value={ LightIntensity.BrightLight }>Яркий непрямой свет (возле открытого окна)</option>
            <option value={ LightIntensity.DirectSunLight }>Прямое воздействие солнечного света (на открытом воздухе или у солнечного окна)</option>
        </select>
    )
};

export default LightIntensitySelect;