import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { useParams } from 'react-router-dom';
import Preloader from '../../common/ui/preloader';
import { getHabitTrackerChartData } from './data/habit-tracker-data-service';
import { showToast } from '../../common/domain/toast-provider';
import { useEffect, MouseEvent } from 'react';
import { startOfDay, lastDayOfMonth, startOfMonth } from 'date-fns';
import { formatHabitTrackerChartData } from './domain/habit-tracker-provider';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import { ChartIcon } from '../../common/ui/icons';
import HabitTrackerTitle from './ui/habit-tracker-title';
import HabitChart from './ui/habit-chart';
import ActionsBar from './ui/actions-bar';
import { getTextFromMarkdown } from '../../common/markdown/markdown-provider';
import { useHabitTrackerStore } from './data/habit-tracker-store';

const HabitTrackerHome = () => {

    const { loading } = useAuthStatus();

    const { todo_id } = useParams();
    const _todo_id = Number(todo_id) || 0;

    const chartData = useHabitTrackerStore(store => store.chartData);
    const setChartData = useHabitTrackerStore(store => store.setChartData);
    const setHabitTrackerTodoParentId = useHabitTrackerStore(store => store.setHabitTrackerTodoParentId);
    const habitTrackerStartDate = useHabitTrackerStore(store => store.habitTrackerStartDate);

    useEffect(() => {
        (async () => {
            setHabitTrackerTodoParentId(_todo_id);

            const firstDay = startOfDay(startOfMonth(habitTrackerStartDate));
            const lastDay = startOfDay(lastDayOfMonth(firstDay));
            const response = await getHabitTrackerChartData(_todo_id, firstDay.getTime(), lastDay.getTime());

            if(!response?.isValid) {
                showToast('Get chart data error.');
            }

            setChartData(formatHabitTrackerChartData(response));
        })();
    }, [_todo_id, setChartData, setHabitTrackerTodoParentId, habitTrackerStartDate]);

    useEffect(() => {
        document.title = getTextFromMarkdown(chartData?.parentTodo?.todo_title) || 'Habit Tracker | Productivity';
    }, [chartData?.parentTodo?.todo_title]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading && chartData && _todo_id &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">

                        <div className="bg-slate-900 text-slate-100 px-4 py-3 flex items-center">
                            <ChartIcon
                                color={ '#fff' }
                                fill={ '#577091' }
                                classes="mr-4"
                            />

                            <span className="mr-10">Habit Tracker</span>

                           {/* <span className="hidden xl:block text-xs ml-auto text-slate-300">questioning approach for critical examination</span>*/}
                        </div>

                        <div className="overflow-auto px-6 pt-4 pb-24 md:pt-10 bg-slate-50 min-h-screen">

                            <button
                                type="button"
                                onClick={ goBack }
                                className="mb-6 md:hidden">
                                &laquo; Back
                            </button>

                            <div className="xl:w-[1100px] max-w-full mx-auto">

                                <HabitTrackerTitle />

                                <ActionsBar />

                                <HabitChart nested={ chartData.habitLogs } />

                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default HabitTrackerHome;