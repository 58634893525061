import { ChartIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';

interface IOpenTodoTitleChartBtn {
    todo: ITodo;
}

const OpenTodoTitleChartBtn = ({ todo }: IOpenTodoTitleChartBtn) => {

    return (
        <a
            className="flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
            target="_blank"
            rel="noreferrer"
            href={ `/todo-title-chart/${ todo.todo_id }` }
            title="Open Todo Title Chart">
            <ChartIcon color={ '#586180' } fill={ '#E0E7FF' } />
        </a>
    )
};

export default OpenTodoTitleChartBtn;