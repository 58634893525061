import { useEffect, useState, MouseEvent as ReactMouseEvent } from 'react';
import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramRectangleWithText } from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { updateHistory } from '../../../../domain/todo-diagram-provider';

interface ITextStyleDropDown {
    useStore: TodoDiagramUseStore;
}

const fontSizes = [
    { value: '0.75rem', label: 'xs', twClass: 'text-xs', },
    { value: '0.875rem', label: 'sm', twClass: 'text-sm', },
    { value: '1rem', label: 'base', twClass: 'text-base', },
    { value: '1.125rem', label: 'lg', twClass: 'text-lg', },
    { value: '1.25rem', label: 'xl', twClass: 'text-xl', },
    { value: '1.5rem', label: '2xl', twClass: 'text-2xl', },
    { value: '1.875rem', label: '3xl', twClass: 'text-3xl', },
    { value: '2.25rem', label: '4xl', twClass: 'text-4xl', },
];

const TextStyleDropDown = ({ useStore } : ITextStyleDropDown) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const selectedElementId = useStore(state => state.selectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
    const selectedElement = foundIndex >= 0 ? data.children[foundIndex] : null;

    const [isOpened, setIsOpened] = useState(false);

    const toggle = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
        setIsOpened(_isOpened => !_isOpened);
    };

    const stopPropagation = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
    };

    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent) => {
            try{
                const element = evt.target as HTMLElement;
                const btn = element.closest('[data-type="text-styles-drop-down"]');
                if(btn) return;

                setIsOpened(false);
            }
            catch (ex) {
                console.log(ex);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const toggleProperty = (prop: string) => {
        const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
        if(foundIndex === -1) return;

        const copy = data.clone();
        const rect = selectedElement as DiagramRectangleWithText;

        switch (prop) {
            case 'bold': {
                rect.isBold = Boolean(!rect.isBold);
                break;
            }

            case 'italic': {
                rect.isItalic = Boolean(!rect.isItalic);
                break;
            }

            case 'underline': {
                rect.isUnderline = Boolean(!rect.isUnderline);
                break;
            }

            case 'text-align-center': {
                rect.textAlign = 'center';
                break;
            }

            case 'text-align-left': {
                rect.textAlign = 'left';
                break;
            }

            case 'text-align-right': {
                rect.textAlign = 'right';
                break;
            }
        }

        copy.children[foundIndex] = rect;

        setDataStack(updateHistory(dataStack, copy));
        setData(copy);
    };

    const toggleFontSizeProperty = (prop: string) => {
        const foundIndex = data?.children?.findIndex(item => item.id === selectedElement?.id);
        if(foundIndex === -1) return;

        const copy = data.clone();
        const rect = selectedElement as DiagramRectangleWithText;
        rect.fontSize = prop;
        copy.children[foundIndex] = rect;

        setDataStack(updateHistory(dataStack, copy));
        setData(copy);
    };

    const rect = selectedElement as DiagramRectangleWithText;

    return (
        <>
            {
                selectedElement &&
                <div
                    data-type="text-styles-drop-down"
                    className="flex items-center mr-2"
                    onClick={ stopPropagation }>
                    <button
                        className="text-xs underline"
                        onClick={ toggle }
                        title="Text Styles"
                        type="button">
                        Text
                    </button>

                    {
                        isOpened &&
                        <div
                            className="absolute top-full left-0 bg-white flex flex-col text rounded p-2 shadow w-48 mt-1">

                            <div className="grid grid-cols-2 gap-2">
                                <div className="flex flex-col border p-2 bg-slate-50">
                                    <button
                                        className={ `text-left ${ rect?.isBold ? 'font-bold' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('bold');
                                        } }
                                        type="button">
                                        Bold
                                    </button>

                                    <button
                                        className={ `text-left mt-2 ${ rect?.isItalic ? 'italic' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('italic');
                                        } }
                                        type="button">
                                        Italic
                                    </button>

                                    <button
                                        className={ `text-left mt-2 ${ rect?.isUnderline ? 'underline' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('underline');
                                        } }
                                        type="button">
                                        Underline
                                    </button>
                                </div>

                                <div className="flex flex-col border p-2 bg-slate-50">
                                    <button
                                        className={ `text-left ${ rect?.textAlign === 'center' ? 'underline' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('text-align-center');
                                        } }
                                        type="button">
                                        Center
                                    </button>

                                    <button
                                        className={ `text-left mt-2 ${ rect?.textAlign === 'left' ? 'underline' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('text-align-left');
                                        } }
                                        type="button">
                                        Left
                                    </button>

                                    <button
                                        className={ `text-left mt-2 ${ rect?.textAlign === 'right' ? 'underline' : '' }` }
                                        onClick={ () => {
                                            toggleProperty('text-align-right');
                                        } }
                                        type="button">
                                        Right
                                    </button>
                                </div>
                            </div>

                            {/*<div className="w-full h-0.5 bg-slate-300 border-t border-t-white mt-2"/>*/ }

                            <div className="grid grid-cols-4 mt-2 border-l border-t bg-slate-50">
                                {
                                    fontSizes.map(({ value, label }) => {
                                        const fontSize = rect?.fontSize ?? '1rem';

                                        return (
                                            <button
                                                key={ label }
                                                className={ `border-r border-b text-center p-2 ${ fontSize === value ? 'underline' : '' }` }
                                                onClick={ () => {
                                                    toggleFontSizeProperty(value);
                                                } }
                                                type="button">
                                                { label }
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
};

export default TextStyleDropDown;