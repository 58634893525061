import { ResponsiveLine } from '@nivo/line';
import { ITodoTitleChartData } from '../todo-title-chart-interfaces';
import { generateGraphData } from '../domain/todo-title-chart-provider';
import { format } from 'date-fns';

interface ITodoTitleChart {
    chartData: ITodoTitleChartData;
}
const TodoTitleChart = ({ chartData }: ITodoTitleChart) => {

    const formatted = generateGraphData(chartData.dataItems);

    return (
        <div className="overflow-auto w-full border bg-white py-4">
            <div style={{
                height: 500,
                minWidth: '500px',
            }}>
                <ResponsiveLine
                    data={ formatted }
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: "linear",
                        stacked: true, // Enables stacking
                        min: "auto",
                        max: "auto",
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickValues: formatted[0].data
                            .map((d, index) => (index % 10 === 0 ? d.x : null)) // Show every 10th raw tick
                            .filter((x) => x !== null), // Remove null values
                        tickSize: 5,
                        tickPadding: 5,
                        format: (value) => format(new Date(value), 'MM.yyyy'), // Format ticks for display
                    }}
                    colors={{ scheme: 'nivo' }}
                    curve="monotoneX" // This adds the monotone smoothing
                    useMesh={ true }
                    tooltip={({ point }) => (
                        <div
                            className="tooltip"
                            style={{
                                background: 'white',
                                padding: '5px',
                                border: '1px solid #ccc',
                                borderRadius: '3px',
                            }}
                        >
                            <strong>Value: </strong>: { point.data.yFormatted }<br/>
                            <strong>Time: </strong> { format(point.data.x, 'dd.MM.yyyy') }
                        </div>
                    )}
                />
            </div>
        </div>
    )
};

export default TodoTitleChart;