import { DragEvent } from 'react';
import { DiagramElementType } from '../../../domain/diagram-elements/diagram-element-type-enum';
import { TODO_DIAGRAM_TOOLBAR_DRAG_CLASS, updateHistory } from '../../../domain/todo-diagram-provider';
import { TodoDiagramUseStore } from '../../../data/store';
import { DiagramRectangleWithText } from '../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { v4 as uuidv4 } from 'uuid';

interface IRectangleWithTextBtnProps {
    useStore: TodoDiagramUseStore;
}

const RectangleWithTextBtn = ({ useStore }: IRectangleWithTextBtnProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);

    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', DiagramElementType.RectangleWithText.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(TODO_DIAGRAM_TOOLBAR_DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    const onClick = () => {
        const copy = data.clone();
        copy.children = [...(copy.children || [])];

        const { cx, cy } = data?.getSvgCenter() || { cx: 0, cy: 0 };

        const shape = new DiagramRectangleWithText(
            uuidv4(),
            DiagramRectangleWithText.defaultText,
            cx - DiagramRectangleWithText.defaultWidth/2,
            cy - DiagramRectangleWithText.defaultHeight/2,
            DiagramRectangleWithText.defaultWidth,
            DiagramRectangleWithText.defaultHeight,
            DiagramRectangleWithText.defaultFill,
            DiagramRectangleWithText.defaultStroke,
            DiagramRectangleWithText.defaultStrokeWidth,
            0,
            0,
            DiagramRectangleWithText.defaultTextColor,
            DiagramRectangleWithText.defaultTextFontSize,
            false,
            false,
            false,
        );
        copy.children.push(shape);

        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
        setSelectedElementId(shape.id);
    };

    return (
        <button
            className="mr-4"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            onClick={ onClick }
            title="Rectangle"
            type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="icon icon-tabler icons-tabler-outline icon-tabler-rectangle">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="2" y="5" width="19" height="14" fill="#fff" stroke="#000" />
                {/*<path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"/>*/}
                {/* text */}
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 8h4"/>
                <path d="M12 8v8"/>
            </svg>
        </button>
    )
};

export default RectangleWithTextBtn;