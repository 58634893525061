import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import { IPlantLocation } from '../../../flowers-interfaces';
import DeletePlantLocation from '../actions/delete-plant-location';
import EditPlantLocation from '../actions/edit-plant-location';
import LightIntensityIcon from '../../light-intensity/light-intensity-icon';

interface IPlantLocationProps {
    plantLocation: IPlantLocation;
}

enum Mode {
    Default = 0,
    Edit = 1,
}

const PlantLocation = ({ plantLocation }: IPlantLocationProps) => {

    const [mode, setMode] = useState<Mode>(Mode.Default);

    const startEdit = () => {
        setMode(Mode.Edit);
    };

    const stopEdit = () => {
        setMode(Mode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === Mode.Default &&
                (
                    <>
                        <div className="flex items-center">
                            <div
                                className="mr-4"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: '#efefef',
                                }}
                            />

                            <button type="button" onClick={ startEdit }>{ plantLocation.location_name }</button>

                            <LightIntensityIcon
                                lightIntensity={ plantLocation.light_intensity }
                            />

                            {
                                !!plantLocation.is_indoor &&
                                <div className="mx-4">🏚️</div>
                            }

                            <Actions classes="plant-type__actions" mr="mr-0" gridSize={ 2 }>
                                <DeletePlantLocation plantLocation={ plantLocation } />

                                <button
                                    title="Edit Plant Type"
                                    className="edit-plant-type flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 } />
                                </button>
                            </Actions>
                        </div>

                        {
                            plantLocation.location_desc &&
                            <div className="ml-7 text-gray-500">{ plantLocation.location_desc }</div>
                        }
                    </>
                )
            }

            {
                mode === Mode.Edit &&
                <EditPlantLocation
                    plantLocation={ plantLocation }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default PlantLocation;