import { deleteJson, get, postJson, putJson, upload } from '../../../common/data/common-data-service';
import { IFoodCategory, IFoodCategoryResponse, IFoodLog, IFoodLogResponse, IFoodResponse } from '../food-interfaces';

// --------------- FOOD CATEGORY -----------------------

export const getAllFoodCategories = async () : Promise<IFoodCategoryResponse> => {
    return await get(`/api/food/category/get-all-categories`);
};

export const insertFoodCategory = async (
    name: string,
    description: string
) : Promise<IFoodCategoryResponse> => {
    return await postJson(`/api/food/category/insert`, {
        name,
        description,
    });
};

export const deleteFoodCategory = async (id: number) : Promise<IFoodCategoryResponse> => {
    return await deleteJson(`/api/food/category/delete`, {
        id,
    });
};

export const updateFoodCategory = async (id: number, name: string, description: string) : Promise<IFoodCategoryResponse> => {
    return await putJson(`/api/food/category/update`, {
        id,
        name,
        description,
    });
};

// --------------- FOOD -----------------------

export const getFood = async (
    foodItemsPageNumber: number,
    foodItemsSelectedCategoryID: number,
    foodItemsSearchTerm: string,
) : Promise<IFoodResponse> => {
    return await get(`/api/food/food-item/get-food?foodItemsPageNumber=${ foodItemsPageNumber }&foodItemsSelectedCategoryID=${ foodItemsSelectedCategoryID }&foodItemsSearchTerm=${ foodItemsSearchTerm }`);
};

export const getFoodDropDownData = async () : Promise<IFoodCategory[]> => {
    return await get(`/api/food/food-item/get-food-drop-down-data`);
};

export const insertFood = async (
    name: string,
    description: string,
    category_id: number,
    calories: number,
    protein: number,
    fat: number,
    carbohydrates: number,

    foodItemsPageNumber: number,
    foodItemsSelectedCategoryID: number,
    foodItemsSearchTerm: string,
) : Promise<IFoodResponse> => {
    return await postJson(`/api/food/food-item/insert`, {
        name,
        description,
        category_id,
        calories,
        protein,
        fat,
        carbohydrates,

        foodItemsPageNumber,
        foodItemsSelectedCategoryID,
        foodItemsSearchTerm,
    });
};

export const deleteFood = async (
    id: number,
    foodItemsPageNumber: number,
    foodItemsSelectedCategoryID: number,
    foodItemsSearchTerm: string,
) : Promise<IFoodResponse> => {
    return await deleteJson(`/api/food/food-item/delete`, {
        id,
        foodItemsPageNumber,
        foodItemsSelectedCategoryID,
        foodItemsSearchTerm,
    });
};

export const updateFood = async (
    id: number,
    name: string,
    description: string,
    category_id: number,
    calories: number,
    protein: number,
    fat: number,
    carbohydrates: number,

    foodItemsPageNumber: number,
    foodItemsSelectedCategoryID: number,
    foodItemsSearchTerm: string,
) : Promise<IFoodResponse> => {
    return await putJson(`/api/food/food-item/update`, {
        id,
        name,
        description,
        category_id,
        calories,
        protein,
        fat,
        carbohydrates,

        foodItemsPageNumber,
        foodItemsSelectedCategoryID,
        foodItemsSearchTerm,
    });
};

// --------------- FOOD ATTACHMENT -----------------------

export const uploadFoodAttachment = async (formData: FormData) : Promise<IFoodResponse> => {
    return await upload('/api/food/attachment/upload', formData);
};

export const deleteFoodAttachment = async (
    food_attachment_id: number,
    foodItemsPageNumber: number,
    foodItemsSelectedCategoryID: number,
    foodItemsSearchTerm: string,
) : Promise<IFoodResponse> => {
    return await deleteJson(`/api/food/attachment/delete`, {
        food_attachment_id,
        foodItemsPageNumber,
        foodItemsSelectedCategoryID,
        foodItemsSearchTerm,
    });
};

// --------------- FOOD LOG ------------------------------

export const getFoodLogs = async (fromDate: number, toDate: number) : Promise<IFoodLogResponse> => {
    return await get(`/api/food/food-log/get-food-logs?fromDate=${ fromDate }&toDate=${ toDate }`);
};

export const insertFoodLog = async (
    fromDate: number,
    toDate: number,

    food_id: number,
    date: number,
    quantity: number,
    description: string
) : Promise<IFoodLogResponse> => {
    return await postJson(`/api/food/food-log/insert`, {
        fromDate,
        toDate,

        food_id,
        date,
        quantity,
        description,
    });
};

export const deleteFoodLog = async (fromDate: number, toDate: number, id: number) : Promise<IFoodLogResponse> => {
    return await deleteJson(`/api/food/food-log/delete`, {
        fromDate,
        toDate,
        id,
    });
};

export const updateFoodLog = async (
    fromDate: number,
    toDate: number,

    id: number,
    food_id: number,
    date: number,
    quantity: number,
    description: string
) : Promise<IFoodLogResponse> => {
    return await putJson(`/api/food/food-log/update`, {
        fromDate,
        toDate,

        id,
        food_id,
        date,
        quantity,
        description,
    });
};

// --------------- FOOD CALENDAR ------------------------------

export const getFoodLogsMin = async (fromDate: number, toDate: number) : Promise<IFoodLog[]> => {
    return await get(`/api/food/food-log/get-food-logs-min?fromDate=${ fromDate }&toDate=${ toDate }`);
};
