import { useEffect, useState, MouseEvent as ReactMouseEvent, ChangeEvent } from 'react';
import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramRectangleWithText } from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { updateHistory } from '../../../../domain/todo-diagram-provider';

interface IBorderStyleDropDown {
    useStore: TodoDiagramUseStore;
}

const BorderStyleDropDown = ({ useStore } : IBorderStyleDropDown) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const selectedElementId = useStore(state => state.selectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
    const selectedElement = foundIndex >= 0 ? data.children[foundIndex] : null;

    const [isOpened, setIsOpened] = useState(false);

    const toggle = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
        setIsOpened(_isOpened => !_isOpened);
    };

    const stopPropagation = (evt: ReactMouseEvent) => {
        evt.stopPropagation();
    };

    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent) => {
            try{
                const element = evt.target as HTMLElement;
                const btn = element.closest('[data-type="border-styles-drop-down"]');
                if(btn) return;

                setIsOpened(false);
            }
            catch (ex) {
                console.log(ex);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const changeBorderWidth = (evt: ChangeEvent<HTMLInputElement>) => {
        evt.stopPropagation();

        const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
        if(foundIndex === -1) return;

        const selectedElement = data.children[foundIndex];
        if(!selectedElement) return;

        const copy = data.clone();
        const rect = selectedElement as DiagramRectangleWithText;
        const value = Number(evt.target.value) || DiagramRectangleWithText.defaultStrokeWidth;
        rect.strokeWidth = value;

        copy.children[foundIndex] = rect;

        setDataStack(updateHistory(dataStack, copy));
        setData(copy);
    };

    return (
        <>
            {
                selectedElement &&
                <div
                    data-type="border-styles-drop-down"
                    className="flex items-center mr-2"
                    onClick={ stopPropagation }>
                    <button
                        className="text-xs underline"
                        onClick={ toggle }
                        title="Border Styles"
                        type="button">
                        Border
                    </button>

                    {
                        isOpened &&
                        <div className="absolute top-full left-0 bg-white border rounded p-2 shadow w-52 mt-1">

                            <label>
                                Border Width:
                                <input
                                    className="border px-2 py-1 rounded w-full mt-2"
                                    value={ (selectedElement as DiagramRectangleWithText)?.strokeWidth ?? DiagramRectangleWithText.defaultStrokeWidth }
                                    type="number"
                                    onInput={ changeBorderWidth }
                                />
                            </label>

                        </div>
                    }
                </div>
            }
        </>
    )
};

export default BorderStyleDropDown;