import { ChangeEvent } from 'react';
import { TodoDiagramUseStore } from '../../data/store';
import { updateHistory } from '../../domain/todo-diagram-provider';

interface ISvgBgColorPicker {
    useStore: TodoDiagramUseStore;
}

const SvgBgColorPicker = ({ useStore }: ISvgBgColorPicker) => {

    const selectedElementId = useStore(state => state.selectedElementId);
    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === selectedElementId);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const selectedElement = foundIndex >= 0 ? data.children[foundIndex] : null;

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const copy = data.clone();
        copy.bgColor = evt.target.value;
        setData(copy);
        setDataStack(updateHistory(dataStack, copy));
    };

    return (
        <>
            {
                !selectedElement &&
                <label className="flex items-center text-slate-500 text-xs bg-white px-2 py-1 border rounded mr-4">
                    <span className="mr-2">SVG BG</span>
                    <input
                        type="color"
                        className="w-4 h-4"
                        value={ data.bgColor || 'rgba(0, 0, 0, 0)' }
                        onChange={ onChange }
                    />
                </label>
            }
        </>
    )
};

export default SvgBgColorPicker;