import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { useParams } from 'react-router-dom';
import { MouseEvent, useEffect } from 'react';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import { ChartIcon } from '../../common/ui/icons';
import { getTodoTitleChartData } from './data/todo-title-chart-data-service';
import { showToast } from '../../common/domain/toast-provider';
import { formatTodoTitleChartChartData } from './domain/todo-title-chart-provider';
import { useTodoTitleChartStore } from './data/todo-title-chart-store';
import { getTextFromMarkdown } from '../../common/markdown/markdown-provider';
import Title from './ui/title';
import TodoTitleChart from './ui/chart';

const TodoTitleChartHome = () => {

    const { loading } = useAuthStatus();

    const { todo_id } = useParams();
    const _todo_id = Number(todo_id) || 0;

    const chartData = useTodoTitleChartStore(store => store.chartData);
    const setChartData = useTodoTitleChartStore(store => store.setChartData);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    const goBack = () => {
        window.history.back();
    };

    useEffect(() => {
        (async () => {

            const response = await getTodoTitleChartData(_todo_id);

            if(!response?.isValid) {
                showToast('Get todo title chart data error.');
            }

            setChartData(formatTodoTitleChartChartData(response));
        })();
    }, [_todo_id, setChartData]);

    useEffect(() => {
        document.title = getTextFromMarkdown(chartData?.parentTodo?.todo_title) || 'Todo Title Chart | Productivity';
    }, [chartData?.parentTodo?.todo_title]);

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading && _todo_id && chartData &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn/>

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu/>
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">

                        <div className="bg-slate-900 text-slate-100 px-4 py-3 flex items-center">
                            <ChartIcon
                                color={ '#fff' }
                                fill={ '#577091' }
                                classes="mr-4"
                            />

                            <span className="mr-10">Todo Title Chart</span>

                            {/* <span className="hidden xl:block text-xs ml-auto text-slate-300">questioning approach for critical examination</span>*/ }
                        </div>

                        <div className="overflow-auto px-6 pt-4 pb-24 bg-slate-50 min-h-screen">

                            <button
                                type="button"
                                onClick={ goBack }
                                className="mb-6 md:hidden">
                                &laquo; Back
                            </button>

                            <div className="max-w-full mx-auto">

                                <Title />

                                <TodoTitleChart chartData={ chartData } />
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
};

export default TodoTitleChartHome;