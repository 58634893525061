import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IPlant, IPlantLocation, IPlantType } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { updatePlant } from '../../../data/flowers-data-service';
import PlantTypesSelect from '../../plant-types/plant-types-select';
import PlantLocationsSelect from '../../plant-locations/plant-locations-select';
import { PlantLifeCycle } from '../../../domain/flowers-provider';
import PlantLifeCycleSelect from '../../plant-life-cycle/plant-life-cycle-select';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import {
    getCompactMarkdownEditorPlugins,
} from '../../../../../common/markdown/markdown-editor-provider';
import EditWateringDate from './edit-watering-date';

interface IEditPlant {
    plant: IPlant;
    close: () => void;
}

const EditPlant = ({ plant, close }: IEditPlant) => {

    const nameRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const setPlantsData = useFlowersStore(store => store.setPlantsData);
    const plantsData = useFlowersStore(store => store.plantsData);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const isNameValid = !nameTouched || name.trim().length > 0;

    const [plantType, setPlantType] = useState<IPlantType|null>(null);
    const [plantTypeTouched, setPlantTypeTouched] = useState(false);
    const isPlantTypeValid = !plantTypeTouched || !!plantType;

    const [plantLocation, setPlantLocation] = useState<IPlantLocation|null>(null);
    const [PlantLocationTouched, setPlantLocationTouched] = useState(false);
    const isPlantLocationValid = !PlantLocationTouched || !!plantLocation;

    const [plantLifeCycleType, setPlantLifeCycleType] = useState<PlantLifeCycle>(PlantLifeCycle.Perennial);
    const [isAlive, setIsAlive] = useState<boolean>(false);
    const [wateringDate, setWateringDate] = useState<number|null>(null);
    const [isLoading, setLoading] = useState(false);

    const [quantity, setQuantity] = useState(0);
    const [quantityTouched, setQuantityTouched] = useState(false);
    const isQuantityValid = !quantityTouched || quantity > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    useEffect(() => {
        setName(plant.plant_name);
        setPlantLifeCycleType(plant.plant_life_cycle_type);
        setIsAlive(plant.is_alive === 1);
        setQuantity(plant.quantity || 0);
        setWateringDate(plant.next_watering);

        editorRef.current?.setMarkdown(plant.plant_desc);

        // const locationId = plant?.plant_location_id ?? 0;
        // const plantLocation = plantsData?.locations.find(item => item.location_id === locationId);
        setPlantLocation(plant.location);

        // const typeId = plant?.plant_type_id ?? 0;
        // const plantType = plantsData?.plantTypes.find(item => item.type_id === typeId);
        setPlantType(plant.plantType);

    }, [plant, plantsData?.locations, plantsData?.plantTypes]);

    const save = useCallback(async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        if(!plantType) {
            setPlantTypeTouched(true);
            return;
        }

        if(!plantLocation) {
            setPlantLocationTouched(true);
            return;
        }

        if(quantity <= 0) {
            setQuantityTouched(true);
            return;
        }

        const description = editorRef.current?.getMarkdown() || '';

        setLoading(true);

        const response = await updatePlant(
            plant.plant_id,
            name,
            description,
            plantType?.type_id || 0,
            plantLocation?.location_id || 0,
            plantLifeCycleType,
            isAlive,
            quantity,
            wateringDate,
        );

        setLoading(false);

        if(!response) {
            showToast('Update plant error.');
            return;
        }

        setPlantsData(response);
        close();
    }, [
        close, isAlive, name,
        plant?.plant_id, plantLifeCycleType,
        plantLocation, plantType,
        quantity, setPlantsData,
        wateringDate,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {
            evt.stopPropagation();

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onPlantLifeCycleTypeChange = (_plantLifeCycleType: PlantLifeCycle) => {
        setPlantLifeCycleType(_plantLifeCycleType);
    };

    const onPlantTypeChange = (_plantType: IPlantType|null) => {
        if(!_plantType) return;
        setPlantType(_plantType);
    };

    const onPlantLocationChange = (_planLocation: IPlantLocation|null) => {
        if(!_planLocation) return;
        setPlantLocation(_planLocation);
    };

    const onIsAliveChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.checked;
        setIsAlive(!value);
    };

    const onQuantityChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number((evt.target as HTMLInputElement).value) || 0;
        setQuantity(value);
        setQuantityTouched(true);
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Description</div>

                <div className="markdown-editor border rounded-lg bg-white">
                    <MDXEditor
                        ref={ editorRef }
                        markdown={ '' }
                        plugins={ getCompactMarkdownEditorPlugins() }
                    />
                </div>
            </label>

            <div className="grid grid-cols-2 gap-4">

                <EditWateringDate
                    date={ wateringDate }
                    setDate={ setWateringDate }
                />

                <label className="flex items-center">
                    <input
                        checked={ isAlive }
                        onInput={ onIsAliveChange }
                        className={ `border rounded px-4 py-2` }
                        type="checkbox"
                    />
                    <div className="ml-4">Is Alive</div>
                </label>

                <label className="flex flex-col">
                    <div className="font-bold text-slate-400 mb-2">Type</div>
                    <PlantTypesSelect
                        onSelect={ onPlantTypeChange }
                        type={ plantType }
                        types={ plantsData?.plantTypes || [] }
                    />

                    {
                        !isPlantTypeValid &&
                        <div className="text-red-700 text-xs mt-1">The plant type is required.</div>
                    }
                </label>

                <label className="flex flex-col">
                    <div className="font-bold text-slate-400 mb-2">Location</div>
                    <PlantLocationsSelect
                        onSelect={ onPlantLocationChange }
                        location={ plantLocation }
                        locations={ plantsData?.locations || [] }
                    />

                    {
                        !isPlantLocationValid &&
                        <div className="text-red-700 text-xs mt-1">The plant location is required.</div>
                    }
                </label>

                <label className="flex flex-col">
                    <div className="font-bold text-slate-400 mb-2">Life Cycle</div>
                    <PlantLifeCycleSelect
                        value={ plantLifeCycleType }
                        onSelect={ onPlantLifeCycleTypeChange }
                    />
                </label>

                <label className="flex flex-col">
                    <div className="font-bold text-slate-400 mb-2">Quantity</div>
                    <input
                        value={ quantity }
                        onInput={ onQuantityChange }
                        className={ `border rounded px-4 py-1 ${ isQuantityValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                        type="number"
                    />

                    {
                        !isQuantityValid && <div className="text-red-700 text-xs mt-1">The quantity is required.</div>
                    }
                </label>

            </div>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' }/>
                }

                {
                    !isLoading &&
                    <>
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2 mr-4"
                            onClick={ close }
                            type="button">Close
                        </button>

                        <button
                            className="bg-teal-500 text-slate-100 rounded px-6 py-2"
                            onClick={ save }
                            type="button">Save
                        </button>
                    </>
                }
            </div>

        </div>
    )
};

export default EditPlant;