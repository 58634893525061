import { ChangeEvent } from 'react';
import { IPlantType } from '../../flowers-interfaces';

interface IPlantTypesSelect {
    type: IPlantType|null;
    types: IPlantType[];
    onSelect: (_type: IPlantType|null) => void;
}

const PlantTypesSelect = ({ type, types, onSelect }: IPlantTypesSelect) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const type_id = Number(evt.target.value) || 0;
        onSelect(types.find(item => item.type_id === type_id));
    };

    return (
        <select
            className="w-full border rounded px-4 py-2"
            value={ type?.type_id ?? 0 }
            onChange={ onChange }>

            <option value={ 0 }>Select Plant Type</option>

            {
                types.map(plantType => {
                    return (
                        <option
                            key={ plantType.type_id }
                            value={ plantType.type_id }>{ plantType.type_name }</option>
                    )
                })
            }
        </select>
    )
};

export default PlantTypesSelect;