import { IPlantWaterCalendarMonth } from '../../flowers-interfaces';
import DayView from './day-view';

interface IMonthView {
    month: IPlantWaterCalendarMonth;
    plant_id: number;
}

const weekTitleClasses = 'bg-slate-50 text-slate-900 p-1 text-xs flex items-center justify-center';

const MonthView = ({ month, plant_id }: IMonthView) => {

    const emptyCells = [];

    for(let i= 0; i<month.startDayOfWeek; i++) {
        emptyCells.push(<div className="bg-slate-50 p-1">&nbsp;</div>);
    }

    return (
        <div className="border-l border-t p-2 text-slate-500">
            <div className="mb-2 flex items-center justify-between">
                <span>{ month.monthName }</span>

                {/*{
                    !!month.avgCalories &&
                    <span className="text-sm">{ Math.round(month.avgCalories) } ккал</span>
                }*/}
            </div>

            <div className="grid grid-cols-7 gap-1">
                <div className={ weekTitleClasses }>ВК</div>
                <div className={ weekTitleClasses }>ПН</div>
                <div className={ weekTitleClasses }>ВТ</div>
                <div className={ weekTitleClasses }>СР</div>
                <div className={ weekTitleClasses }>ЧТ</div>
                <div className={ weekTitleClasses }>ПТ</div>
                <div className={ weekTitleClasses }>СБ</div>

                { emptyCells }

                {
                    month.days?.map(day => {
                        return (
                            <DayView
                                key={ `${ month.id }-${ day.id }` }
                                day={ day }
                                plant_id={ plant_id }
                            />
                        )
                    })
                }
            </div>
        </div>
    )
};

export default MonthView;