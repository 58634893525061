import { ArrowLeftIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';

const PrevYearButton = () => {

    const currentCalendarYear = useFoodStore(store => store.currentCalendarYear);
    const setCurrentCalendarYear = useFoodStore(store => store.setCurrentCalendarYear);

    const jumpTPrevMonth = () => {
        setCurrentCalendarYear(currentCalendarYear - 1);
    };

    return (
        <button
            title="Previous Year"
            onClick={ jumpTPrevMonth }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevYearButton;