import { LightIntensity } from '../../domain/flowers-provider';

interface ILightIntensityIcon {
    lightIntensity: LightIntensity;
}

const LightIntensityIcon = ({ lightIntensity }: ILightIntensityIcon) => {
    return (
        <div className="mr-2">
            {
                lightIntensity === LightIntensity.LowLight &&
                <div title="Минимальное непрямое освещение (затененные углы в помещении)">☁️</div>
            }

            {
                lightIntensity === LightIntensity.MediumLight &&
                <div title="Умеренный непрямой свет (возле окна с занавесками)">🌥️</div>
            }

            {
                lightIntensity === LightIntensity.BrightLight &&
                <div title="Яркий непрямой свет (возле открытого окна)">🌤️</div>
            }

            {
                lightIntensity === LightIntensity.DirectSunLight &&
                <div title="Прямое воздействие солнечного света (на открытом воздухе или у солнечного окна">☀️</div>
            }
        </div>
    )
};

export default LightIntensityIcon;