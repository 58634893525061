import {
    mapToSvgCoordinates,
    SELECTION_CIRCLE_COLOR,
    SELECTION_CIRCLE_RADIUS, tryGetSvgElement, updateHistory
} from '../../../../domain/todo-diagram-provider';
import { PointerEvent as ReactPointerEvent, MouseEvent } from 'react';
import { TodoDiagramUseStore } from '../../../../data/store';
import { DiagramRectangleWithText } from '../../../../domain/diagram-elements/elements/diagram-rectangle-with-text';
import { DiagramArrow } from '../../../../domain/diagram-elements/elements/diagram-arrow';
import { v4 as uuidv4 } from 'uuid';

interface IRectangleSelectionCircleProps {
    index: number;
    cx: number;
    cy: number;
    lineEndX: number;
    lineEndY: number;
    cursor: string;
    useStore: TodoDiagramUseStore;
    shape: DiagramRectangleWithText;
    fill: string;
    stroke: string;
}

const RectangleSelectionCircle = ({
                                      index,
                                      cx,
                                      cy,
                                      lineEndX,
                                      lineEndY,
                                      cursor,
                                      useStore,
                                      shape,
                                      fill,
                                      stroke,
}: IRectangleSelectionCircleProps) => {

    const data = useStore(state => state.data);
    const setData = useStore(state => state.setData);
    const foundIndex = data?.children?.findIndex(item => item.id === shape.id);
    const dataStack = useStore(state => state.dataStack);
    const setDataStack = useStore(state => state.setDataStack);
    const setSelectedElementId = useStore(state => state.setSelectedElementId);

    const onClick = (evt: MouseEvent) => {
        evt.stopPropagation();

        if(evt.detail === 2) {

            /**
             * On circle double click, draw an arrow
             * from this circle outside.
             */
            evt.preventDefault();

            const copy = data.clone();
            copy.children = [...(copy.children || [])];

            const arrowShape = new DiagramArrow(
                uuidv4(),
                cx,
                cy,
                lineEndX,
                lineEndY,
                DiagramArrow.arrowHeadSize,
                '#000',
                2,
            );
            copy.children.push(arrowShape);

            setData(copy);
            setDataStack(updateHistory(dataStack, copy));
            setSelectedElementId(arrowShape.id);
        }
    };

    const onCircleDrag = (moveEvent: PointerEvent) => {
        if(foundIndex === -1) return;

        const $svg = tryGetSvgElement(moveEvent.target);
        if(!$svg) return;

        const updatedShape = shape.clone();

        const { x: newX, y: newY } = mapToSvgCoordinates($svg, moveEvent.clientX, moveEvent.clientY);

        switch (index) {
            case 0: {
                // Top-left
                updatedShape.x = newX;
                updatedShape.y = newY;
                updatedShape.width = shape.x + shape.width - newX;
                updatedShape.height = shape.y + shape.height - newY;
                break;
            }
            case 1: {
                // Top-center
                updatedShape.y = newY;
                updatedShape.height = shape.y + shape.height - newY;
                break;
            }
            case 2: // Top-right
                updatedShape.y = newY;
                updatedShape.width = newX - shape.x;
                updatedShape.height = shape.y + shape.height - newY;
                break;
            case 3: {
                // Middle-left
                updatedShape.x = newX;
                updatedShape.width = shape.x + shape.width - newX;
                break;
            }
            case 4: {
                // Middle-right
                updatedShape.width = newX - shape.x;
                break;
            }
            case 5: {
                // Bottom-left
                updatedShape.x = newX;
                updatedShape.width = shape.x + shape.width - newX;
                updatedShape.height = newY - shape.y;
                break;
            }
            case 6: {
                // Bottom-center
                updatedShape.height = newY - shape.y;
                break;
            }
            case 7: {
                // Bottom-right
                updatedShape.width = newX - shape.x;
                updatedShape.height = newY - shape.y;
                break;
            }
        }

        const copy = data.clone();
        copy.children[foundIndex] = updatedShape;
        setData(copy);
    };

    const onPointerDown = (evt: ReactPointerEvent<SVGCircleElement>) => {
        evt.preventDefault();

        const onPointerMove = (moveEvent: PointerEvent) => {
            onCircleDrag(moveEvent);
        };

        const onPointerUp = () => {
            setDataStack(updateHistory(dataStack, data));
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
    };

    return (
        <circle
            style={{
                cursor,
            }}
            cx={ cx }
            cy={ cy }
            r={ SELECTION_CIRCLE_RADIUS }
            stroke={ stroke || SELECTION_CIRCLE_COLOR }
            fill={ fill || SELECTION_CIRCLE_COLOR }
            onPointerDown={ onPointerDown }
            onClick={ onClick }
        />
    )
};

export default RectangleSelectionCircle;